import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {getApplication$} from '../../../core/store/application';
import {getPriceInfo$} from 'fe-starter-shared';
import {getPerformance$} from 'fe-starter-shared';
import {Performance} from 'fe-starter-shared';
import {PriceInfo} from 'fe-starter-shared';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {Step} from '../../../core/models/step.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmService} from 'fe-starter-shared';
import {ClientConfigService} from 'fe-starter-shared';
import * as socketsActions from 'fe-starter-shared';
import {AuthenticationService} from 'fe-starter-shared';
import {environment} from '../../../../environments/environment';
import {getCartState$} from '../../../booking/modules/cart/store';
import {AcrossTabsChildService} from '../../services/across-tabs.service';
import {BookingProcessService} from 'fe-starter-shared';
import {getProcess$} from 'fe-starter-shared';

@Component({
  selector: 'c360-navbar',
  templateUrl: './navbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NavbarComponent implements OnInit, OnDestroy, OnChanges {

  @Input() hasFatalError = false;
  public applicationState$ = this._store.pipe((getApplication$));
  public supportedLanguages: string[] = [];
  public priceInfo: PriceInfo = null;
  public canExitImmediately = false;
  public location: Location;


  private subscription = new Subscription();
  private _performance: Performance = null;

  private activeStep: Step;
  public previousUrl: number;
  public skipSaleFee: boolean = null;

  /** Constructor */
  constructor(public translate: TranslateService,
              private _store: Store<any>,
              private _router: Router,
              private _cd: ChangeDetectorRef,
              private _route: ActivatedRoute,
              private _clientConfigService: ClientConfigService,
              private _acrossTabService: AcrossTabsChildService,
              private _authenticationService: AuthenticationService,
              private _confirmService: ConfirmService,
              private _bookingProcessService: BookingProcessService) {
  }

  ngOnInit() {
    this.subscription.add(
      this.applicationState$.subscribe((state) => {
        this.activeStep = state.steps.find((step) => step.active);
      })
    ).add(
      this._store.pipe(getCartState$).subscribe(cartState => {
        if (cartState != null) {
          this.skipSaleFee = cartState.skipSaleFee;
        } else {
          this.skipSaleFee = null;
        }
        this._cd.detectChanges();
      })
    ).add(
      this._store.pipe((getPriceInfo$)).subscribe((priceInfo: PriceInfo) => {
        this.priceInfo = priceInfo;
        this.canExitImmediately = true;
        // this.canExitImmediately = this._authenticationService.isSalesCounter();
        this._cd.detectChanges();
      })
    ).add(
      this._store.pipe((getPerformance$)).subscribe((performance: Performance) => {
        this._performance = performance;
      })
    );
    // TODO Use getLangs for real multi language support
    // this.supportedLanguages = this.translate.getLangs();
    this.supportedLanguages = [this.translate.getDefaultLang()];

    this.previousUrl = window.history.length;

    if (!this._authenticationService.isSalesCounter()) {
      this.previousUrl = window.history.length;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes != null && changes.hasFatalError != null) {
      this.hasFatalError = changes.hasFatalError.currentValue;
    } else {
      this.hasFatalError = false;
    }
    this._cd.detectChanges();
  }

  private goTo(step: string): void {
    this._router.navigate([step], {relativeTo: this._route});
  }

  changeLanguage(language): void {
    this.translate.use(language);
  }

  isCurrentLanguage(language): boolean {
    return language === this.translate.currentLang;
  }

  handleBackClick(): void {
    if (this.activeStep == null || this.activeStep.previous == null) {
      this.exit();
    } else {
      if (this.activeStep.previous === 'details' && this._authenticationService.isSalesCounter()) {
        this.goTo('seating');
      } else {
        this.goTo(this.activeStep.previous);
      }
    }
  }

  exit(): void {
    if (this._authenticationService.isSalesCounter()) {
      this._store.dispatch(
        new socketsActions.SocketsCloseConnection()
      );
      this._store.pipe(getProcess$).subscribe((process) => {
          this._bookingProcessService
            .deleteProcess(process.performanceId)
            .toPromise()
            .then(
              p => {
                if (this._acrossTabService.isConnected()) {
                  window.close();
                } else {
                  window.location.href = environment.urls.cms + environment.urls.cmsSchedule;
                }
              },
              (err) => {
              }
            );
        }
      );
    } else {
      this._confirmService.confirm({
        title: 'Wollen Sie die Buchung verlassen?',
        message: 'Sicher?',
        yesButtonCaption: 'Ja',
        noButtonCaption: 'Nein'
      }).then(
        () => {
          this._store.dispatch(
            new socketsActions.SocketsCloseConnection()
          );
          this._store.pipe(getProcess$).subscribe((process) => {
              this._bookingProcessService
                .deleteProcess(process.performanceId)
                .toPromise()
                .then(
                  p => {
                    if (window.opener != null) {
                      window.close();
                    } else {
                      let link = null;

                      if (this._performance != null && this._performance.locationId != null &&
                        environment.locations[this._performance.locationId] != null &&
                        environment.locations[this._performance.locationId].website != null) {
                        link = environment.locations[this._performance.locationId].website;
                      } else {
                        link = environment.urls.website;
                      }
                      window.location.href = link;
                    }
                  },
                  (err) => {
                  }
                );
            }
          );
        },
        () => {
        }
      );
    }
  }

  onStepSelected(step): void {
    this.goTo(step);
  }

  scrollToCart(): void {
    const cart = document.getElementById('CartComponent');
    cart.scrollIntoView();
  }
}
