import {Action} from '@ngrx/store';
import {PriceInfo} from 'fe-starter-shared';

export class CartActions {
  static INIT_CART = '[Cart] Init Cart';

  static CART_UPDATE_RECEIVED = '[Cart] Update Received';
  static CART_UPDATE_REQUEST = '[Cart] Update Request';
  static CART_UPDATE_SKIP = '[Cart] Update Skip';

  static CART_UPDATE_PENDING = '[Cart] Update Pending';
  static CART_UPDATE_TIMEOUT = '[Cart] Update Timeout';
  static CHECK_FOR_RESPONSE = '[Cart] Update Check For Response';

  static SET_FEE = '[Cart] Set Fee';
  static CART_ADD_VOUCHER = '[Cart] Add Voucher';
  static CART_REMOVE_VOUCHER = '[Cart] Remove Voucher';
}

export class InitCart implements Action {
  readonly type = CartActions.INIT_CART;
}
export class CartUpdateRequest implements Action {
  readonly type = CartActions.CART_UPDATE_REQUEST;
}

export class CartUpdateReceived implements Action {
  readonly type = CartActions.CART_UPDATE_RECEIVED;
  constructor(public payload: PriceInfo) {}
}
export class CartUpdateSkip implements Action {
  readonly type = CartActions.CART_UPDATE_SKIP;
}

export class CheckForResponse implements Action {
  readonly type = CartActions.CHECK_FOR_RESPONSE;
}

export class CartUpdateTimeout implements Action {
  readonly type = CartActions.CART_UPDATE_TIMEOUT;
}

export class SetFee implements Action {
  readonly type = CartActions.SET_FEE;

  constructor(public payload: boolean) {}
}

export class CartAddVoucher implements Action {
  readonly type = CartActions.CART_ADD_VOUCHER;
  constructor(public payload: string) {}
}
export class CartRemoveVoucher implements Action {
  readonly type = CartActions.CART_REMOVE_VOUCHER;
  constructor(public payload: string) {}
}
