import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FeStarterSharedModule, TranslationLoaderService} from 'fe-starter-shared';
import {TicketingComponent} from './containers/ticketing.container';
import {TicketComponent} from './components/ticket/ticket.component';
import {german} from './i18n/client/de';
import {english} from './i18n/client/en';
import {TicketListComponent} from './components/ticket-list/ticket-list.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FeStarterSharedModule,
    NgbModule
  ],
  declarations: [
    TicketingComponent,
    TicketListComponent,
    TicketComponent
  ],
  exports: [
    TicketingComponent,
    TicketListComponent
  ],
  providers: []
})
export class TicketingModule {
  constructor(
    private translationLoader: TranslationLoaderService
  ) {
    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(german, english);
  }
}
