import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IsAuthorizedGuard} from './guard/is-authorized.guard';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {AuthenticationService, FeStarterSharedModule, TranslationLoaderService} from 'fe-starter-shared';
import {german} from './i18n/client/de';
import {english} from './i18n/client/en';
import {LoginFormComponent} from './components/login-form/login-form';
import {LoginGuestOrRegistrationComponent} from './components/guest-or-registration/guest-or-registration.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HasValidCustomerRecordGuard} from './guard/has-valid-customer-record.guard';
import {LoginForgotPasswordComponent} from './components/forgot-pasword/login-forgot-password.component';
import {NgxTrimDirectiveModule} from 'ngx-trim-directive';
import {PersonalFormComponent} from './components/personal-form/personal-form';
import {CustomerControlComponent} from './components/customer-control/customer-control';
import {PersonalDetailsFormComponent} from './components/personal-details-form/personal-details.form';
import {CUSTOM_ERROR_MESSAGES, NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {CustomErrorFactory} from '../core/validators/custom.errors';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    FeStarterSharedModule,
    NgbModule,
    NgxTrimDirectiveModule,
    NgBootstrapFormValidationModule
  ],
  declarations: [
    PersonalFormComponent,
    LoginFormComponent,
    LoginGuestOrRegistrationComponent,
    CustomerControlComponent,
    LoginForgotPasswordComponent,
    PersonalDetailsFormComponent
  ],
  exports: [
    LoginFormComponent,
    CustomerControlComponent,
  ]
})
export class LoginModule {
  constructor(
    private translationLoader: TranslationLoaderService
  ) {
    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(german, english);
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LoginModule,
      providers: [
        AuthenticationService,
        IsAuthorizedGuard,
        HasValidCustomerRecordGuard,
        {
          provide: CUSTOM_ERROR_MESSAGES,
          useFactory: CustomErrorFactory,
          deps: [TranslateService],
          multi: true
        }
      ]
    };
  }
}
