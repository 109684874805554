import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {getMandator$} from 'fe-starter-shared';
import {Mandator} from 'fe-starter-shared';
import {LegalModalType, LegalService} from '../../services/legal.service';

@Component({
  selector: 'c360-legal-modal',
  templateUrl: './legal.modal.component.html'
})

export class LegalModalComponent implements OnInit, OnDestroy {

  @ViewChild('legalContent', {static: false}) legalContent;

  private _legalContentRef: NgbModalRef;
  private _subscription: Subscription;
  private _mandator: Mandator;

  public legalTitle: string;
  public content: string;

  /** Constructor */
  constructor(private _store: Store<any>,
              private _legalService: LegalService,
              private _modalService: NgbModal) {
  }

  ngOnInit(): void {
    this._subscription = new Subscription();
    this._subscription.add(
      this._store.pipe(getMandator$).subscribe(mandator => {
        this._mandator = mandator;
      })
    ).add(
      this._legalService.showLegalModal$().subscribe((mode: LegalModalType) => {
        if (mode !== LegalModalType.NONE) {
          this.openExternalContent(mode);
        } else if (this._legalContentRef != null) {
          this._legalContentRef.close();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  openExternalContent(type: LegalModalType) {
    this.legalTitle = 'FOOTER.' + type.toString();

    switch (type) {
      case LegalModalType.IMPRINT:
        this.content = this._mandator.imprint;
        break;
      case LegalModalType.TERMS_AND_CONDITIONS:
        this.content = this._mandator.termsAndConditions;
        break;
      case LegalModalType.DATA_PROTECTION_GUIDELINES:
        this.content = this._mandator.dataProtectionGuidelines;
        break;
    }

    if (this.legalTitle != null && this.content != null) {
      this._legalContentRef = this._modalService.open(this.legalContent, {
        size: 'lg'
      });
      this._legalContentRef.result.then(
        () => {
          this._legalService.close();
        },
        () => {
          this._legalService.close();
        }
      );
    }
  }
}
