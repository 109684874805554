<div class="modal-header px-3">
  <h4 class="modal-title">Browser Prüfung</h4>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="alert alert-danger mb-0 text-center">
    <b>Wir unterstützen Ihren Browser leider nicht!</b><br><br>Bitte benutzen Sie die aktuellste Version von:<br><a href="https://www.google.de/chrome/">Chrome</a>, <a href="https://www.mozilla.org/de/firefox/new/">Firefox</a>, <a href="https://support.apple.com/de-de/HT204416">Safari</a>, <a href="https://www.microsoft.com/edge">Edge</a> oder <a href="https://www.opera.com/">Opera</a>.
  </p>
</div>
<div class="modal-footer">
  <button ngbAutofocus class="btn btn-success" (click)="onClose()">Ignorieren</button>
</div>