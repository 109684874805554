<ng-container *ngIf="isRequestPending">
  <c360-loading-bar message="{{'PAYMENT.LOADING' | translate}}"></c360-loading-bar>
</ng-container>

<ng-container *ngIf="!isRequestPending && priceInfo">
  <p>
    <span *ngIf="data.selectedPaymentMethod"><i class="{{data.selectedPaymentMethod.icon}}" aria-hidden="true"></i> {{'PAYMENT.PAYMENT_METHODS.' + data.selectedPaymentMethod.method | translate}}</span><br/>
    <b class="mt-4" *ngIf="skipSaleFee" [translate]="'PAYMENT.TOTAL'" [translateParams]="{amount: priceInfo.fullAmount  | localeCurrency:'symbol':'1.2'}"></b>
    <b class="mt-4" *ngIf="!skipSaleFee" [translate]="'PAYMENT.TOTAL'" [translateParams]="{amount: priceInfo.fullAmountWithFee  | localeCurrency:'symbol':'1.2'}"></b>
  </p>
  <div *ngIf="useStoredPayment">
    <div>
      <span [translate]="'PAYMENT.STORED.' + data.paymentInfo.providerType +'.TITLE'"></span><br/>
      <b>{{data.paymentInfo.providerTypeIdMasked}}</b><br/>
      <button class="btn btn-secondary btn-block mt-3 py-3" (click)="clearStoredPayment()" [translate]="'PAYMENT.STORED.' + data.paymentInfo.providerType +'.CHANGE'"></button>
    </div>
  </div>

  <form role="form"
        *ngIf="paymentForm && !useStoredPayment"
        (ngSubmit)="submitForm()"
        [formGroup]="paymentForm">
    <div class="form-group mt-5">
      <label for="form-sepa-providerTypeCustomerName" [translate]="'PAYMENT.FORMS.' + data.paymentInfo.providerType +'.LABEL_NAME'"></label>
      <input
        autocomplete="providerTypeCustomerName"
        class="form-control"
        formControlName="providerTypeCustomerName"
        id="form-sepa-providerTypeCustomerName"
        placeholder="{{'PAYMENT.FORMS.' + data.paymentInfo.providerType +'.PLACEHOLDER_NAME' | translate}}"
        type="text">
    </div>

    <div class="form-group">
      <label for="form-sepa-providerTypeId" [translate]="'PAYMENT.FORMS.' + data.paymentInfo.providerType +'.LABEL_IBAN'"></label>
      <input
        autocomplete="iban"
        mask='SS00 0000 0000 0000 0000 00 00*'
        [validation]="false"
        class="form-control"
        formControlName="providerTypeId"
        id="form-sepa-providerTypeId"
        placeholder="{{'PAYMENT.FORMS.' + data.paymentInfo.providerType +'.PLACEHOLDER_IBAN' | translate}}"
        type="text"
        title="{{'PAYMENT.FORMS.' + data.paymentInfo.providerType +'.HINT_IBAN' | translate }}">
    </div>

    <span [translate]="'BASE.FORM.REQUIRED_FIELDS'"></span>

    <button type="submit" class="btn btn-block btn-primary btn-lg py-3" [translate]="'PAYMENT.FORMS.' + data.paymentInfo.providerType +'.SUBMIT'"></button>

  </form>
</ng-container>
