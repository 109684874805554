export const localeBaseDe = {
  lang: 'de',
  data: {
    PAYMENT: {
      REQUEST_PENDING: 'Zahlungsdaten aktualisiert',
      PAYMENT_METHOD: 'Zahlungsmittel',
      LOADING: 'Lade Zahlungsmittel',
      TOTAL: 'Betrag: {{amount}}',
      PAYMENT_FORM: {
        LABEL_ACCOUNT_HOLDER: 'Kontoinhaber*',
        LABEL_IBAN: 'IBAN*',
        PAYMENT_BUTTON: 'Kostenpflichtig kaufen',
      },
      OPTIONS_TITLE: 'Sie können mit folgenden Zahlungsmitteln bezahlen:',
      SELECT_TITLE: 'Wählen Sie Ihr Zahlungsmittel:',
      PAYMENT_METHODS: {
        DIRECT_DEBIT: 'Lastschrift',
        VOUCHER: 'Gutschein',
        EC_TERMINAL: 'EC Terminal',
        CASH: 'Barzahlung',
        INVOICE_FILM_DISTRIBUTOR: 'Verleihgutschein',
        PAYPAL: 'PayPal',
        PAYDIREKT: 'Paydirekt',
        KLARNA: 'Überweisung',
        CREDIT_CARD: 'Kreditkarte',
        CREDIT_CARD_VISA: 'Visa',
        CREDIT_CARD_MASTER_CARD: 'mastercard',
        CREDIT_CARD_AMERICAN_EXPRESS: 'American Express',
        CREDIT_CARD_DINERS_DISCOVER: 'Diners Club',
        CREDIT_CARD_JCB: 'JCB',
        CREDIT_CARD_MAESTRO_INTERNATIONAL: 'maestro',
        CREDIT_CARD_CHINA_UNION_PAY: 'Union Pay',
        CREDIT_CARD_UATP_AIRPLUS: 'Airplus',
        I_DEAL: 'iDeal',
      },
      FORMS: {
        DIRECT_DEBIT: {
          LABEL_NAME: 'Kontoinhaber*:',
          PLACEHOLDER_NAME: 'Kontoinhaber',
          LABEL_IBAN: 'IBAN*:',
          PLACEHOLDER_IBAN: 'IBAN',
          HINT_IBAN: 'Eine deutsche IBAN hat 22 Stellen und beginnt mit DE',
          SUBMIT: 'Zahlungsdaten bestätigen'
        },
        CASH: {
          TITLE: 'Barzahlung'
        },
        EC_TERMINAL: {
          TITLE: 'EC-Zahlung'
        },
        VOUCHER: {
          TITLE: 'Der Rechnungsbetrag wird komplett durch Gutscheine beglichen.'
        },
        INVOICE_FILM_DISTRIBUTOR: {
          TITLE: 'Verleihgutschein'
        },
        CREDIT_CARD: {
          LABEL_ACCOUNT_HOLDER: 'Karteninhaber*:',
          LABEL_CARDPAN: 'Kartennummer*:',
          LABEL_CVC2: 'Kartenprüfnummer*:',
          LABEL_EXPIRY: 'Gültig bis*:',
          REQUIRED_FIELDS: 'Bitte alle Pflichfelder ausfüllen!',
          NOT_VALID: 'Ihre Zahlungsdaten wurden abgelehnt!',
          REDIRECT_ABORTED: 'Der Zahlungsvorgang wurde abgebrochen.',
          REDIRECT_FAILED: 'Der Zahlungsvorgang ist fehlgeschlagen.',
          SUBMIT: 'Zahlungsdaten hinterlegen',
          WAITING_TIME: 'Soabld Sie zum 3D Secure Verfahren weitergeleitet wurden, haben Sie {{time}} Minuten Zeit die Zahlung abzuschließen. Ansonsten werden Ihre Plätze wieder freigegeben.',
        },
        PAYPAL: {
          VALID: 'Ihre Zahlungsdaten wurden erfolreich hinterlegt.',
          REDIRECT_ABORTED: 'Der Zahlungsvorgang wurde abgebrochen.',
          REDIRECT_FAILED: 'Der Zahlungsvorgang ist fehlgeschlagen.',
          REDIRECT_WAITING_FOR_PROVIDER_STATUS: 'Warten auf Antwort von PayPal...',
          SUBMIT: 'PayPal-Link öffnen',
          WAITING_TIME: 'Soabld Sie zu PayPal weitergeleitet wurden, haben Sie {{time}} Minuten Zeit die Zahlung abzuschließen. Ansonsten werden Ihre Plätze wieder freigegeben.',
          LOADING: 'Der PayPal-Link wird erstellt',
        },
        PAYDIREKT: {
          VALID: 'Ihre Zahlungsdaten wurden erfolreich hinterlegt.',
          REDIRECT_ABORTED: 'Der Zahlungsvorgang wurde abgebrochen.',
          REDIRECT_FAILED: 'Der Zahlungsvorgang ist fehlgeschlagen.',
          REDIRECT_WAITING_FOR_PROVIDER_STATUS: 'Warten auf Antwort von Paydirekt...',
          SUBMIT: 'Paydirekt-Link öffnen',
          WAITING_TIME: 'Soabld Sie zu Paydirekt weitergeleitet wurden, haben Sie {{time}} Minuten Zeit die Zahlung abzuschließen. Ansonsten werden Ihre Plätze wieder freigegeben.',
        },
        KLARNA: {
          VALID: 'Ihre Zahlungsdaten wurden erfolreich hinterlegt.',
          REDIRECT_ABORTED: 'Der Zahlungsvorgang wurde abgebrochen.',
          REDIRECT_FAILED: 'Der Zahlungsvorgang ist fehlgeschlagen.',
          REDIRECT_WAITING_FOR_PROVIDER_STATUS: 'Warten auf Antwort von Klarna...',
          SUBMIT: 'Klarna-Link öffnen',
          WAITING_TIME: 'Soabld Sie zu Klarna weitergeleitet wurden, haben Sie {{time}} Minuten Zeit die Zahlung abzuschließen. Ansonsten werden Ihre Plätze wieder freigegeben.',
        }
      },
      STORED: {
        DIRECT_DEBIT: {
          TITLE: 'Zuletzt verwendete Bankverbindung:',
          CHANGE: 'Zahlungsdaten ändern'
        },
        CREDIT_CARD: {
          TITLE: 'Kreditkartennummer:',
          CHANGE: 'Zahlungsdaten ändern'
        },
        PAYPAL: {
          REDIRECT: 'PayPal-Link öffnen'
        },
        PAYDIREKT: {
          REDIRECT: 'Paydirekt-Link öffnen'
        },
        KLARNA: {
          REDIRECT: 'Klarna-Link öffnen'
        }
      }
    },
  }
};
