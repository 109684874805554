import {Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Scheduler} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTER_NAVIGATION, RouterNavigationAction} from '@ngrx/router-store';
import {TrackingService} from 'fe-starter-shared';
import {TitleService} from '../../services/title.service';
import {map} from 'rxjs/operators';

export const SEARCH_DEBOUNCE = new InjectionToken<number>('Search Debounce');
export const SEARCH_SCHEDULER = new InjectionToken<Scheduler>(
  'Search Scheduler'
);

/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

export class ApplicationEffects {

  @Effect({dispatch: false})
  onRouteChange = this.actions$.pipe(
    ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
    map(action => action.payload),
    map(action => {
      const title = this._titleService.getTitle(action.event.urlAfterRedirects);
      this._trackingService.pushPageview(action.event.urlAfterRedirects, title);
    })
  );

  constructor(private actions$: Actions,
              private _router: Router,
              private route: ActivatedRoute,
              private _titleService: TitleService,
              private _trackingService: TrackingService,
              @Optional()
              @Inject(SEARCH_DEBOUNCE)
              private debounce: number = 300,
              /**
               * You inject an optional Scheduler that will be undefined
               * in normal application usage, but its injected here so that you can mock out
               * during testing using the RxJS TestScheduler for simulating passages of time.
               */
              @Optional()
              @Inject(SEARCH_SCHEDULER)
              private scheduler: Scheduler) {
  }
}
