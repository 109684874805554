import {Inject, InjectionToken, Optional} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Scheduler} from 'rxjs';
import * as layoutActions from './layout.actions';
import * as processActions from 'fe-starter-shared';
import {map} from 'rxjs/operators';

export const SEARCH_DEBOUNCE = new InjectionToken<number>('Search Debounce');
export const SEARCH_SCHEDULER = new InjectionToken<Scheduler>(
  'Search Scheduler'
);

export class LayoutEffects {

  constructor(private actions$: Actions,
              @Optional()
              @Inject(SEARCH_DEBOUNCE)
              private debounce: number = 300,
              @Optional()
              @Inject(SEARCH_SCHEDULER)
              private scheduler: Scheduler) {
  }

  @Effect()
  onProcessLoadCompleteAction$ = this.actions$.pipe(
    ofType<processActions.ProcessLoadComplete>(processActions.ProcessActions.LOAD_COMPLETE),
    map(action => new layoutActions.ProcessReady(action))
  );

  @Effect()
  onProcessLoadErrorAction$ = this.actions$.pipe(
    ofType<processActions.ProcessLoadError>(processActions.ProcessActions.LOAD_ERROR),
    map(action => new layoutActions.HideSplashscreen())
  );
}
