import {ErrorMessage} from 'ng-bootstrap-form-validation';
import {TranslateService} from '@ngx-translate/core';

export const CustomErrorFactory = (translateService: TranslateService): ErrorMessage[] => {
  return [
    {
      error: 'required',
      format: (label, error) => requiredFormat(label, error, translateService)
    },
    {
      error: 'invalidEmailAddress',
      format: (label, error) => invalidEmailAddressFormat(label, error, translateService)
    },
    {
      error: 'matchEmail',
      format: (label, error) => matchEmailFormat(label, error, translateService)
    },
    {
      error: 'securePassword',
      format: (label, error) => securePasswordFormat(label, error, translateService)
    },
    {
      error: 'matchPassword',
      format: (label, error) => matchPasswordFormat(label, error, translateService)
    },
    {
      error: 'iban',
      format: (label, error) => ibanFormat(label, error, translateService)
    },
    {
      error: 'remote',
      format: (label, error) => remoteFormat(label, error, translateService)
    },
    {
      error: 'voucherAlreadyUsed',
      format: (label, error) => voucherAlreadyUsed(label, error, translateService)
    }
  ];
};

export function requiredFormat(label: string, error: any, translateService: TranslateService): string {
  return translateService.instant('VALIDATION_ERROR.REQUIRED_FIELD', {
    label: label.replace('*:', '')
  });
}

export function invalidEmailAddressFormat(label: string, error: any, translateService: TranslateService): string {
  return translateService.instant('VALIDATION_ERROR.INVALID_EMAIL', {
    label: label.replace('*:', '')
  });
}

export function matchEmailFormat(label: string, error: any, translateService: TranslateService): string {
  return translateService.instant('VALIDATION_ERROR.MATCH_EMAIL', {
    label: label.replace('*:', '')
  });
}

export function securePasswordFormat(label: string, error: any, translateService: TranslateService): string {
  return translateService.instant('VALIDATION_ERROR.SECURE_PASSWORD', {
    label: label.replace('*:', '')
  });
}

export function matchPasswordFormat(label: string, error: any, translateService: TranslateService): string {
  return translateService.instant('VALIDATION_ERROR.MATCH_PASSWORD', {
    label: label.replace('*:', '')
  });
}

export function ibanFormat(label: string, error: any, translateService: TranslateService): string {
  return translateService.instant('VALIDATION_ERROR.IBAN', {
    label: label.replace('*:', '')
  });
}

export function remoteFormat(label: string, error: any, translateService: TranslateService): string {
  return translateService.instant('VALIDATION_ERROR.REMOTE', {
    params: error
  });
}

export function voucherAlreadyUsed(label: string, error: any, translateService: TranslateService): string {
  return translateService.instant('VALIDATION_ERROR.VOUCHER_ALREADY_USED');
}
