import {localeBaseEn} from '../en';
import {mergeDeep} from 'fe-starter-shared';

const localeClientEn = {
  data: {
    TICKETING: {
      HEADER: 'Tickets'
    }
  }
};

export const english = mergeDeep({}, localeClientEn, localeBaseEn);
