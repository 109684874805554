import * as layoutActions from './layout.actions';
import {Action} from '@ngrx/store';

export interface LayoutState {
  showSplashscreen: boolean;
  showWelcomeBar: boolean;
  showStepNavigation: boolean;
}

const initialState: LayoutState = {
  showSplashscreen: true,
  showWelcomeBar: false,
  showStepNavigation: false
};

interface UnsafeAction extends Action {
  payload?: any;
}

export function layout(state: LayoutState = initialState,
                       action: UnsafeAction): LayoutState {
  switch (action.type) {
    case layoutActions.LayoutActions.HIDE_SPLASHSCREEN:
      return {
        ...state,
        showSplashscreen: false
      };

    case layoutActions.LayoutActions.SHOW_SPLASHSCREEN:
      return {
        ...state,
        showSplashscreen: true
      };

    case layoutActions.LayoutActions.PROCESS_READY:
      return {
        ...state,
        showSplashscreen: false
      };

    case layoutActions.LayoutActions.SET:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
}
