import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {RootState} from 'fe-starter-shared';
import {getPaymentMethods$, isFullyPayedWithVouchers$} from 'fe-starter-shared';
import * as sharedProcessActions from 'fe-starter-shared';
import {PaymentMethod, PaymentMethods} from 'fe-starter-shared';
import {environment} from '../../../../../../environments/environment';
import {AuthenticationService} from 'fe-starter-shared';
import {PaymentProviderTypes} from 'fe-starter-shared';
import * as cartActions from '../../../../modules/cart/store/cart.actions';
import {getCartState$} from '../../../cart/store';
import {PaymentInfoRequestType} from 'fe-starter-shared';
import {getPaymentInfo$} from 'fe-starter-shared';
import {Subscription} from 'rxjs';

@Component({
  selector: 'c360-payment-method-selector',
  templateUrl: './method-selector.component.html'
})
export class PaymentMethodSelectorComponent implements OnInit, OnDestroy {

  @Output() onPaymentSelected: EventEmitter<string> = new EventEmitter<string>();

  private _subscriptions: Subscription;
  public selectedPaymentProviderType: string = null;
  private _paymentMethods: PaymentMethod[] = null;
  private _waitingForConfirmation = false;

  public ignoredPaymentMethods: string[] = [];

  public isFullyPayedWithVouchers = false;

  private _hasOnlyOneSelectableOption = false;
  private skipSaleFee: boolean;

  get hasOnlyOneSelectableOption(): boolean {
    return this._hasOnlyOneSelectableOption;
  }

  get paymentMethods(): PaymentMethod[] {
    return this._paymentMethods;
  }

  constructor(private _store: Store<RootState>,
              private _authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this._subscriptions = new Subscription();
    this._subscriptions
      .add(
        this._store.pipe(isFullyPayedWithVouchers$).subscribe(
          (isFullyPayedWithVouchers: boolean) => {
            this.isFullyPayedWithVouchers = isFullyPayedWithVouchers;
          }
        )
      ).add(
      this._store.pipe(getPaymentInfo$).subscribe((selected) => {
        this._waitingForConfirmation = false;
        if (selected != null && selected.providerType != null) {
          if (this.selectedPaymentProviderType !== selected.providerType) {
            if (this.selectedPaymentProviderType === PaymentProviderTypes.INVOICE_FILM_DISTRIBUTOR) {
              this._store.dispatch(
                new cartActions.SetFee(this.skipSaleFee)
              );
            } else if (selected.providerType === PaymentProviderTypes.INVOICE_FILM_DISTRIBUTOR) {
              this._store.dispatch(
                new cartActions.SetFee(true)
              );
            }
            this.selectedPaymentProviderType = selected.providerType;
            this.onPaymentSelected.emit(selected.providerType);
          }
        }

        if (this._authenticationService.isSalesCounter()) {
          this.ignoredPaymentMethods = environment.ignoredPaymentMethodsForSalesCounter;
          if (this.selectedPaymentProviderType == null) {
            this.selectPayment_cash();
          }
        } else {
          this.ignoredPaymentMethods = environment.ignoredPaymentMethods;
        }
      })
    ).add(
      this._store.pipe(getPaymentMethods$).subscribe((paymentMethods: PaymentMethods) => {
        this._paymentMethods = [];
        // Filter Payments and check if only one is left
        this._hasOnlyOneSelectableOption = Object.keys(paymentMethods.paymentMethods)
          .filter(
            name => this.ignoredPaymentMethods.indexOf(name) === -1
          ).length === 1;

        Object.keys(paymentMethods.paymentMethods).forEach((name) => {
          const paymentMethod = paymentMethods.paymentMethods[name];
          if (this.ignoredPaymentMethods.indexOf(name) === -1) {
            this._paymentMethods.push(paymentMethod);
            if (this._hasOnlyOneSelectableOption && !this.isFullyPayedWithVouchers) {
              this.selectPayment(paymentMethod);
            }
          }
        });
      })
    ).add(
      this._store.pipe(getCartState$).subscribe(cartState => {
        if (this.selectedPaymentProviderType !== PaymentProviderTypes.INVOICE_FILM_DISTRIBUTOR) {
          this.skipSaleFee = cartState.skipSaleFee;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  selectPayment(paymentMethod: PaymentMethod) {
    if (!this._waitingForConfirmation && paymentMethod.method !== this.selectedPaymentProviderType) {
      this.onPaymentSelected.emit(null);
      this._waitingForConfirmation = true;
      this._store.dispatch(
        new sharedProcessActions.ProcessPaymentInfoRequestSent({
          type: PaymentInfoRequestType.SELECT,
          provider: paymentMethod.provider,
          providerType: paymentMethod.method,
          providerTypeId: null,
          providerTypeIdMasked: null,
          providerTypeCustomerName: null,
          skipSaleFee: false
        })
      );
    } else {
      this._waitingForConfirmation = false;
      this.onPaymentSelected.emit(paymentMethod.method);
    }
  }

  selectPayment_cash() {
    this.onPaymentSelected.emit(null);
    this._waitingForConfirmation = true;
    this._store.dispatch(
      new sharedProcessActions.ProcessPaymentInfoRequestSent({
        type: PaymentInfoRequestType.SELECT,
        provider: 'DEFAULT',
        providerType: 'CASH',
        providerTypeId: null,
        providerTypeIdMasked: null,
        providerTypeCustomerName: null,
        skipSaleFee: false
      })
    );
  }
}
