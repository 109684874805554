import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location, PopStateEvent} from '@angular/common';
import {Subscription} from 'rxjs';
import {LoggerService} from 'fe-starter-shared';
import {Store} from '@ngrx/store';
import {getShowSplashscreen$} from '../store/layout';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'c360-root',
  templateUrl: './app.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private splashScreen$ = this._store.pipe((getShowSplashscreen$));
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  private _subscriptions: Subscription = new Subscription();

  public showSplashscreen: boolean;

  constructor(private _store: Store<any>,
              private router: Router,
              private location: Location,
              private _logger: LoggerService) {
    /**
     * Selectors can be applied with the `select` operator which passes the state
     * tree to the provided selector
     */
    _logger.info('AppComponent log info');

    this._subscriptions.add(
      this.splashScreen$.subscribe((showSplashscreen) => {
        setTimeout(
          () => {
            this.showSplashscreen = showSplashscreen;
          }
        );
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }


  ngOnInit() {
    this._subscriptions.add(
      this.location.subscribe((ev: PopStateEvent) => {
        this.lastPoppedUrl = ev.url;
      })
    );

    this._subscriptions.add(
      this.router.events.subscribe((ev: any) => {
        if (ev instanceof NavigationStart) {
          if (ev.url !== this.lastPoppedUrl) {
            this.yScrollStack.push(window.scrollY);
          }
        } else if (ev instanceof NavigationEnd) {
          if (ev.url === this.lastPoppedUrl) {
            this.lastPoppedUrl = undefined;
            window.scrollTo(0, this.yScrollStack.pop());
          } else {
            window.scrollTo(0, 0);
          }
        }
      })
    );
  }
}
