import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FeStarterSharedModule, TranslationLoaderService} from 'fe-starter-shared';
import {german} from './i18n/client/de';
import {english} from './i18n/client/en';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PaymentMethodSelectorComponent} from './components/method-selector/method-selector.component';
import {PaymentOptionsComponent} from './components/options/payment-options.component';
import {PaymentFormHolderComponent} from './components/form/payment-form-holder.component';
import {PaymentFormDirective} from './components/form/payment-form-host.directive';
import {DefaultCashComponent} from './components/form/default/cash.component';
import {DefaultECTerminalComponent} from './components/form/default/ec-terminal.component';
import {DefaultDirectDebitComponent} from './components/form/default/direct-debit.component';
import {PaymentProviderService} from './service/payment-provider.service';
import {CoreModule} from '../../../core/core.module';
import {DefaultInvoiceFilmDistributorComponent} from './components/form/default/invoice-film-distributor.component';
import {DefaultVoucherComponent} from './components/form/default/voucher.component';
import {NgxMaskModule} from 'ngx-mask';
import {PaymentPayoneHostedService} from './service/payment-payone-hosted.service';
import {PayoneCreditCardComponent} from './components/form/payone/credit-card.component';
import {PayoneExternalComponent} from './components/form/payone/external.component';
import {PayoneExternalDirectlyComponent} from './components/form/payone/external-directly.component';
import {PaymentCrefopayHostedService} from './service/payment-crefopay-hosted.service';
import {CrefopaySecureFieldsComponent} from './components/form/crefopay/secure-fields.component';
import {NgxTrimDirectiveModule} from 'ngx-trim-directive';
import {CUSTOM_ERROR_MESSAGES, NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {CustomErrorFactory} from '../../../core/validators/custom.errors';

const COMPONENTS = [
  PaymentFormDirective,
  PaymentFormHolderComponent,
  PaymentMethodSelectorComponent,
  PaymentOptionsComponent,
  DefaultCashComponent,
  DefaultECTerminalComponent,
  DefaultDirectDebitComponent,
  DefaultVoucherComponent,
  DefaultInvoiceFilmDistributorComponent,
  PayoneCreditCardComponent,
  PayoneExternalComponent,
  PayoneExternalDirectlyComponent,
  CrefopaySecureFieldsComponent
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    FeStarterSharedModule,
    NgbModule,
    NgxMaskModule.forChild(),
    NgxTrimDirectiveModule,
    NgBootstrapFormValidationModule
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    ...COMPONENTS
  ],
  entryComponents: [
    DefaultCashComponent,
    DefaultECTerminalComponent,
    DefaultDirectDebitComponent,
    DefaultVoucherComponent,
    DefaultInvoiceFilmDistributorComponent,
    PayoneCreditCardComponent,
    PayoneExternalComponent,
    PayoneExternalDirectlyComponent,
    CrefopaySecureFieldsComponent
  ]
})
export class PaymentModule {

  constructor(private translationLoader: TranslationLoaderService) {
    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(german, english);
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PaymentModule,
      providers: [
        PaymentProviderService,
        PaymentCrefopayHostedService,
        PaymentPayoneHostedService,
        {
          provide: CUSTOM_ERROR_MESSAGES,
          useFactory: CustomErrorFactory,
          deps: [TranslateService],
          multi: true
        }
      ]
    };
  }
}
