<!--suppress HtmlUnknownAttribute -->
<div ngClass="(withWrapper)?'sk-folding-cube-wrapper':''">
  <div class="sk-folding-cube">
    <div class="sk-cube1 sk-cube"></div>
    <div class="sk-cube2 sk-cube"></div>
    <div class="sk-cube4 sk-cube"></div>
    <div class="sk-cube3 sk-cube"></div>
  </div>
  <h3 *ngIf="message" class="text-center" [translate]="message" [translateParams]="messageParams"></h3>
</div>
