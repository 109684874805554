import {ApplicationActions} from './application.actions';
import {Action} from '@ngrx/store';
import {Step} from '../../models/step.model';
import {ApplicationModes} from 'fe-starter-shared';

export interface ApplicationState {
  title: string;
  mode: ApplicationModes;
  steps: Step[];
  initialUrl: string | null;
  initComplete: boolean;
  startOfBooking: number;
  backAllowed: boolean;
}

const initialState: ApplicationState = {
  title: 'BASE.TITLE',
  mode: ApplicationModes.REGULAR_SALE,
  steps: [
    {
      name: 'seating',
      title: 'BASE.STEPS.SEATING',
      previous: null,
      next: 'details',
      active: false,
      layoutOptions: null
    },
    {
      name: 'details',
      title: 'BASE.STEPS.PERSONAL_DETAILS',
      previous: 'seating',
      next: 'payment',
      active: false,
      layoutOptions: null
    },
    {
      name: 'payment',
      title: 'BASE.STEPS.PAYMENT',
      previous: 'details',
      next: null,
      active: false,
      layoutOptions: null
    }
  ],
  initialUrl: null,
  initComplete: false,
  startOfBooking: null,
  backAllowed: false,
};

interface UnsafeAction extends Action {
  payload?: any;
}

export function application(state: ApplicationState = initialState,
                            action: UnsafeAction): ApplicationState {

  switch (action.type) {
    case ApplicationActions.SET:
      return {
        ...state,
        ...action.payload
      };

    case ApplicationActions.SET_APPLICATION_MODE:
      return {
        ...state,
        mode: action.payload
      };

    case ApplicationActions.ACTIVATE_APPLICATION_STEP:
      const newSteps = Object.assign([], state.steps.map((step) => {
        return  {...step, active: (step.name === action.payload.name)};
      }));

      return {
        ...state,
        steps: newSteps
      };

    case ApplicationActions.SET_INITIAL_URL:
      return {
        ...state,
        initialUrl: action.payload
      };

    default:
      return state;
  }
}
