import * as cartActions from './cart.actions';
import {Action} from '@ngrx/store';
import {PriceInfo, PriceRow} from 'fe-starter-shared';

export class CartState {
  initial: boolean;
  requestPending: boolean;
  requestTimestamp: number;
  skipSaleFee: boolean;
  voucherList: string[];
}

const initialState: CartState = {
  initial: true,
  requestPending: false,
  requestTimestamp: null,
  skipSaleFee: null,
  voucherList: []
};

interface UnsafeAction extends Action {
  payload?: any;
}

const getAppliedVoucherFromPriceInfo = (priceInfo: PriceInfo): string[] => {
  let appliedVouchers: string[] = [];

  if (priceInfo != null) {
    if (priceInfo.voucherValueInfo) {
      appliedVouchers = [
        ...appliedVouchers,
        ...priceInfo.voucherValueInfo.map(voucherInfo => voucherInfo.code)
      ];
    }
    if (priceInfo.priceTicketMappings != null) {
      priceInfo.priceTicketMappings.forEach((mapping: PriceRow) => {
        if (mapping.ticketPricesInfos.voucherInfoList && mapping.ticketPricesInfos.voucherInfoList.length > 0) {
          appliedVouchers = [
            ...appliedVouchers,
            ...mapping.ticketPricesInfos.voucherInfoList.map(voucherInfo => voucherInfo.code)
          ];
        }
      });
    }
  }

  return appliedVouchers;
};

export function cart(
  state: CartState = initialState,
  action: UnsafeAction
): CartState {

  switch (action.type) {

    case cartActions.CartActions.CART_UPDATE_RECEIVED:
    case cartActions.CartActions.CART_UPDATE_TIMEOUT:

      return {
        ...state,
        initial: false,
        requestPending: false,
        requestTimestamp: null,
        voucherList: getAppliedVoucherFromPriceInfo(action.payload)
      };

    case cartActions.CartActions.CART_UPDATE_REQUEST:
      return {
        ...state,
        requestPending: true,
        requestTimestamp: performance.now()
      };

    case cartActions.CartActions.SET_FEE:
      return {
        ...state,
        skipSaleFee: action.payload
      };

    case cartActions.CartActions.CART_ADD_VOUCHER:
      return {
        ...state,
        voucherList: [
          ...state.voucherList,
          action.payload
        ]
      };
    case cartActions.CartActions.CART_REMOVE_VOUCHER:
      return {
        ...state,
        voucherList: state.voucherList.filter(voucherCode => voucherCode !== action.payload)
      };
    default:
      return state;
  }
}
