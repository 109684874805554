<div id="no-interaction-overlay" *ngIf="isConnectionError">
  <div id="message">
    <div class="waiting-animation text-center">
      <img src="assets/images/loading_spinner.png" alt="">
      <h4 class="mt-4">Verbindung unterbrochen</h4>
      <small>Einen Augenblick, wir versuchen die Verbindung wiederherzustellen</small>
    </div>
  </div>
</div>
<div *ngIf="error && !isConnectionError" class="c360-alert c360-alert-leftborder c360-alert-{{getErrorType()}}">
  <ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="error.errorCode === errorCodes.PROCESS_EXISTS">
      <p [translate]="'BASE.SESSION_EXISTS'"></p>
      <button class="mr-2 mb-2 btn btn-primary" (click)="closeAlert(errorActions.CONTINUE_SESSION)" [translate]="'BASE.CONTINUE_SESSION'"></button>
      <button class="mr-2 mb-2 btn btn-primary" (click)="closeAlert(errorActions.NEW_SESSION)" [translate]="'BASE.NEW_SESSION'"></button>
    </ng-container>

    <ng-container *ngSwitchCase="error.errorCode === errorCodes.PS1 || error.errorCode === errorCodes.PS2 || error.errorCode === errorCodes.PS3">
      <h4 class="alert-heading" [translate]="error.errorUserMessage"></h4>
      <div class="row text-center">
        <div class="col-12">
          <button class="mr-2 mb-2 btn btn-{{getErrorType()}}" (click)="closeAlert(errorActions.EXIT)" [translate]="'BASE.BACK_TO_HOMEPAGE'"></button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <h4 class="alert-heading" [translate]="error.errorUserMessage"></h4>
      <div class="row text-center">
        <div class="col-12">
          <button class="mr-2 mb-2 btn btn-{{getErrorType()}}" (click)="closeAlert(error.errorAction)" *ngIf="error.errorAction === errorActions.EXIT" [translate]="'BASE.BACK_TO_HOMEPAGE'"></button>
        </div>
      </div>

      <ng-container [ngSwitch]="error.errorAction">
        <ng-container *ngSwitchCase="errorActions.RETRY_LAST">
          <ng-container *ngIf="error.attempt <= error.maxReattempts && error.attemptAction">
            <div class="waiting-animation--small">
              <img src="assets/images/loading_spinner.png" alt="">
              <p>
                <span [translate]="'BASE.ERROR.RETRY_INFO'" [translateParams]="{seconds: error.attemptInterval / 1000}"></span>
                <span [translate]="'BASE.ERROR.RETRY_PROGRESS'" [translateParams]="{attempt: error.attempt, max: error.maxReattempts}"></span>
              </p>
            </div>
          </ng-container>
          <div class="row text-center">
            <div class="col-12">
              <button class="mr-2 mb-2 btn btn-{{getErrorType()}}" (click)="closeAlert(error.errorAction)" *ngIf="error.errorAction === errorActions.RETRY_LAST" [translate]="'BASE.ERROR.ALERT_RETRY_LAST'"></button>
            </div>
          </div>
          <p *ngIf="error.attempt > error.maxReattempts" [translate]="'BASE.ERROR.RETRY_DONE'"></p>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div class="row text-center">
            <div class="col-12">
              <button class="mr-2 mb-2 btn btn-{{getErrorType()}}" (click)="closeAlert(error.errorAction)" *ngIf="error.errorAction === errorActions.RESTART" [translate]="'BASE.ERROR.RESTART'"></button>
              <button class="mr-2 mb-2 btn btn-{{getErrorType()}}" (click)="closeAlert(error.errorAction)" *ngIf="error.errorAction === errorActions.RETRY_LAST" [translate]="'BASE.ERROR.ALERT_RETRY_LAST'"></button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="error.errorCode">
      <small>[</small><small [translate]="'BASE.ERROR.CODE'" [translateParams]="{code: error.errorCode}"></small><small *ngIf="error.requestId"> / DebugID: {{error.requestId}}</small><small>]</small>
    </ng-container>

  </ng-container>
</div>
