import {LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {CoreEffects, CoreReducers, metaReducers} from './core/store';
import * as moment from 'moment';
import 'moment/locale/de';
import {CoreModule} from './core/core.module';
import {AppComponent} from './core/containers/app';
import {CustomRouterStateSerializer} from './utils';
import {CoreGuard} from './core/guard';
import {InitGuard} from './booking/guards';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {LoginModule} from './login/login.module';
import {AppRoutingModule} from './app-routing.module';
import {environment} from '../environments/environment';
import {FeStarterSharedModule} from 'fe-starter-shared';
import {TokenInterceptor} from './core/services/token-interceptor';
import {NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {PaymentModule} from './booking/modules/payment/payment.module';
import {CartModule} from './booking/modules/cart/cart.module';

registerLocaleData(localeDe);

export function getLocalStorage() {
  return (typeof window !== 'undefined') ? window.localStorage : null;
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    SimpleNotificationsModule.forRoot(),
    StoreModule.forRoot(CoreReducers, {metaReducers}),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot(CoreEffects),
    environment.hasDevTools ? StoreDevtoolsModule.instrument() : [],
    FeStarterSharedModule.forRoot({
      config: {
        environment
      }
    }),
    CoreModule.forRoot(),
    LoginModule.forRoot(),
    PaymentModule.forRoot(),
    CartModule.forRoot(),
    NgBootstrapFormValidationModule.forRoot()
  ],
  declarations: [],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: environment.locale
    },
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: 'LOCALSTORAGE',
      useFactory: getLocalStorage
    },
    CoreGuard,
    InitGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private translate: TranslateService
  ) {
    this.translate.addLangs(environment.languages);
    this.translate.setDefaultLang(environment.defaultLanguage);
    this.translate.use(this.translate.getDefaultLang());
    moment().locale('de');
  }
}


