import {Store} from '@ngrx/store';
import {ApplicationState} from './application.reducers';
import {AppState} from '../reducers';
import {Step} from '../../models/step.model';
import {ApplicationModes} from 'fe-starter-shared';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export function getApplication$(state$: Store<AppState>): Observable<ApplicationState> {
  return state$.select(state => state.application);
}

export function getApplicationMode$(state$: Store<AppState>): Observable<ApplicationModes> {
  return state$.select(state => state.application.mode);
}

export function getApplicationSteps$(state$: Store<AppState>): Observable<Step[]> {
  return state$.select(state => state.application.steps);
}

export function getActiveApplicationStep$(state$: Store<AppState>): Observable<Step> {
  return state$.select(state => state.application.steps).pipe(
    map((steps) => {
      return steps.find((step) => {
        return step.active;
      });
    })
  );
}

export function getInitialUrl$(state$: Store<AppState>): Observable<string> {
  return state$.select(state => state.application.initialUrl);
}
