export enum SocketRequestTypes {
  CUSTOMER = 'customer',
  PAYMENT_INFO = 'payment-info',
  COMPLETION = 'completion',
  SELECTION = 'selection',
  PRICE_INFO = 'price-info',
  TICKET_ADDRESSES = 'ticket-addresses'
}

export enum SocketCloseReasons {
  SHUTDOWN,
  RECONNECT
}
