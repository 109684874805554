import {ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {routerReducer as router, RouterReducerState} from '@ngrx/router-store';
import {storeFreeze} from 'ngrx-store-freeze';
import {RouterStateUrl} from '../../utils';
import {layout, LayoutActions, LayoutState} from './layout';
import {LayoutEffects} from './layout/layout.effects';
import {application, ApplicationActions, ApplicationState} from './application';
import {ApplicationEffects} from './application/application.effects';

export interface AppState {
  layout: LayoutState;
  application: ApplicationState;
  router: RouterReducerState<RouterStateUrl>;
}

export const CoreReducers: ActionReducerMap<AppState> = {
  layout,
  application,
  router
};

export let CoreActions = [LayoutActions, ApplicationActions];
export let CoreEffects = [LayoutEffects, ApplicationEffects];

export function logger(actionReducer: ActionReducer<AppState>): ActionReducer<AppState> {
  // tslint:disable-next-line:only-arrow-functions
  return function(state: AppState, action: any): AppState {
    return actionReducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [logger, storeFreeze]
  : [];


export const getRouterState$ = createFeatureSelector('router');
export const getRouterParams$ = createSelector(getRouterState$, (routerReducerState: RouterReducerState<RouterStateUrl>) => {
  return routerReducerState.state.params;
});
