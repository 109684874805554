<div class="modal-header">
    <h4 class="modal-title">{{ options.title}}</h4>
    <button aria-label="Close" class="close" type="button" (click)="no()">
      <!--suppress HtmlUnknownTarget -->
      <img alt="" src="assets/images/icon/primary/close.svg">
    </button>
  </div>
  <div class="modal-body">
    <p>{{ options.message }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="yes()">{{options.yesButtonCaption}}</button>
    <button type="button" class="btn btn-secondary" (click)="no()">{{options.noButtonCaption}}</button>
  </div>