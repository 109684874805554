import {Injectable} from '@angular/core';
import {TrackingService} from 'fe-starter-shared';
import {LoggerService} from 'fe-starter-shared';
import * as AcrossTabs from 'across-tabs/dist/across-tabs';
import {TabCommunicationMessage, TabCommunicationMessageTypes} from '../models/tab-communication-message.interface';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class AcrossTabsChildService {

  private _child = null;
  private _tabId = null;
  private _isConnectedToParent = false;

  private _parentMessages: BehaviorSubject<TabCommunicationMessage> = new BehaviorSubject<TabCommunicationMessage>(null);

  constructor(private _trackingService: TrackingService,
              private _logger: LoggerService) {
    try {
      this._child = new AcrossTabs.default.Child({
        onReady: this.onReady,
        onInitialize: this.onInitialize,
        handshakeExpiryLimit: 4000,
        onParentDisconnect: this.onParentDisconnect,
        onParentCommunication: this.onParentCommunication
      });
    } catch (e) {
      this._trackingService.pushGAEvent(
        'Error',
        'AcrossTab Init',
        e.message
      );
      this._child = null;
    }
  }

  private onReady() {
  }
  private onInitialize = () => {
    if (this._child != null) {
      const tabInfo = this._child.getTabInfo();
      this._tabId = tabInfo.id;
      this._isConnectedToParent = true;
      this.sendToParent({
        id: this._tabId,
        type: TabCommunicationMessageTypes.CHILD_READY,
        body: 'Booking Client Connected'
      });
    }
  }

  private onParentDisconnect = () => {
    this._isConnectedToParent = false;
  }

  private onParentCommunication = (dataReceived: TabCommunicationMessage) => {
    this._parentMessages.next(dataReceived);
  }

  public sendToParent(msg: Partial<TabCommunicationMessage>) {
    if (msg.id == null) {
      msg.id = this._tabId;
    }
    const data = {
      msg: msg,
      id: this._tabId
    };
    this._child.sendMessageToParent(data);
  }

  public isConnected(): boolean {
    return this._isConnectedToParent;
  }

  public onParentMessage$(): Observable<TabCommunicationMessage> {
    return this._parentMessages.asObservable();
  }
}
