export const localeBaseEn = {
  lang: 'en',
  data: {
    CART: {
      TITLE: 'Basket',
      TICKETS: 'Tickets',
      VOUCHER_FORM: {
        TITLE: 'Enter voucher code',
        LABEL_VOUCHER: 'Voucher code',
        REDEEM_BUTTON: 'Apply voucher'
      },
      EMPTY: {
        TITLE: 'Seat selection',
        SUBTITLE: 'Please select your seats'
      },
      LIST: {
        TITLE: 'Seat selection',
        SUBTITLE: 'Total number of tickets',
        TOTAL: 'Total',
        TOTAL_FEE: 'includes {{vatSet}}% USt'
      },
      INLINE: {
        TITLE: 'Your Tickets:',
        ROW: 'Row: {{row}}',
        ROW_POSITIONS: 'Seats: {{rowPositions}}',
      }
    },
  }
};
