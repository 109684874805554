import {Action} from '@ngrx/store';

export class LayoutActions {
  static LAYOUT_READY = '[Layout] Ready';
  static PROCESS_READY = '[Layout] ProcessReady';

  static SHOW_SPLASHSCREEN = '[Layout] Show Splashscreen';
  static HIDE_SPLASHSCREEN = '[Layout] Hide Splashscreen';

  static SET = '[Layout] Set';
}

export class ProcessReady implements Action {
  readonly type = LayoutActions.PROCESS_READY;

  constructor(public payload: any) {}
}

export class LayoutReady implements Action {
  readonly type = LayoutActions.LAYOUT_READY;

  constructor(public payload: any) {}
}

export class ShowSplashscreen implements Action {
  readonly type = LayoutActions.SHOW_SPLASHSCREEN;
}

export class HideSplashscreen implements Action {
  readonly type = LayoutActions.HIDE_SPLASHSCREEN;
}

export class Set implements Action {
  readonly type = LayoutActions.SET;

  constructor(public payload: object) {}
}

export type Actions =
  ShowSplashscreen
  | HideSplashscreen
  | ProcessReady
  | LayoutReady
  | Set;
