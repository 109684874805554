import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {getCustomer$} from 'fe-starter-shared';
import {User} from 'fe-starter-shared';

@Injectable()
export class HasValidCustomerRecordGuard implements CanActivate {

  private _customer: User;

  private _checkoutRoutes = /seating|details|ticket-addresses|payment/ig;

  constructor(private router: Router, private _store: Store<any>) {
    this._store.pipe((getCustomer$)).subscribe(customer => this._customer = customer);
  }

  canActivate(route: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean {
    if (User.isValid(this._customer)) {
      return true;
    } else {
      this.router.navigate([
        routerStateSnapshot.url.replace(this._checkoutRoutes, 'details')
      ]);
      return false;
    }
  }
}
