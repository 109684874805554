import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CoreGuard} from './core/guard';
import {ErrorComponent} from './core/components/error/error.component';
import {PageNotFoundComponent} from './core/components/page-not-found/page-not-found';

const routes: Routes = [
  {
    path: 'checkout/:performanceId',
    loadChildren: './booking/booking.module#BookingModule',
    canActivate: [
      CoreGuard
    ],
  },
  {
    path: 'confirmation/:transactionId',
    loadChildren: './confirmation/confirmation.module#ConfirmationModule'
  },
  {
    path: 'change-email-address/:hash',
    loadChildren: './change-email-address/change-email-address.module#ChangeEmailAddressModule'
  },
  {
    path: 'password-reset/:hash',
    loadChildren: './password-reset/password-reset.module#PasswordResetModule'
  },
  {
    path: 'verify-account/:hash',
    loadChildren: './verify-account/verify-account.module#VerifyAccountModule'
  },
  {
    path: 'account',
    loadChildren: './account/account.module#AccountModule'
  },
  {
    path: 'sso',
    loadChildren: './sso/sso.module#SingleSignOnModule'
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
