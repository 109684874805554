import {Action} from '@ngrx/store';
import {Step} from '../../models/step.model';
import {ApplicationModes} from 'fe-starter-shared';

export class ApplicationActions {
  static SET = '[Application] SET';
  static SET_INITIAL_URL = '[Application] SET INITIAL URL';

  static SET_APPLICATION_MODE = '[Application] SET APP MODE';
  static APPLICATION_MODE_SET = '[Application] APP MODE SET';
  static ACTIVATE_APPLICATION_STEP = '[Application] UPDATE APP STEP';

}

export class SetMode implements Action {
  readonly type = ApplicationActions.SET_APPLICATION_MODE;

  constructor(public payload: ApplicationModes) {
  }
}

export class ActivateStep implements Action {
  readonly type = ApplicationActions.ACTIVATE_APPLICATION_STEP;

  constructor(public payload: Partial<Step>) {
  }
}

export class ApplicationModeSet implements Action {
  readonly type = ApplicationActions.APPLICATION_MODE_SET;

  constructor(public payload: string = null) {
  }
}

export class SetInitialUrl implements Action {
  readonly type = ApplicationActions.SET_INITIAL_URL;

  constructor(public payload: string = null) {
  }
}

export class Set implements Action {
  readonly type = ApplicationActions.SET;

  constructor(public payload: any) {
  }
}

export type Actions =
  Set
  | SetInitialUrl
  | SetMode
  | ApplicationModeSet
  | ActivateStep;
