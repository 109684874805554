import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {PaymentFormComponent, PaymentFormData} from '../payment-form.component';
import {PaymentFormResult} from '../../../models/payment-form-result.model';
import {Subscription} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {PaymentInfo, ValidationError} from 'fe-starter-shared';

@Component({
  templateUrl: './voucher.component.html'
})
export class DefaultVoucherComponent extends PaymentFormComponent implements OnInit, OnDestroy {
  data: PaymentFormData;
  validationErrors: ValidationError[] = [];
  onValid: EventEmitter<PaymentFormResult> = new EventEmitter<PaymentFormResult>();

  _result: PaymentInfo = new PaymentInfo();
  _subscription: Subscription;

  paymentForm: FormGroup;
  public useStoredPayment: boolean;
  public isRequestPending: boolean;

  constructor(private _cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this._result = {
      ...new PaymentInfo(),
      ...this.data.paymentInfo,
    };
    this.onValid.emit({
      useStoredPayment: true,
      bookDirectly: false,
      payment: this._result
    });
  }

  setData(data: PaymentFormData): void {
    this.data = data;
    try {
      this._cd.detectChanges();
    } catch (e) {
      //
    }
  }

  setValidationErrors(validationErrors: ValidationError[]): void {
    return null;
  }

  submitForm(): void {
  }

  ngOnDestroy(): void {
  }
}
