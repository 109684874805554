import {Store} from '@ngrx/store';
import {CartState} from './cart.reducers';
import {Observable} from 'rxjs';

export function getCartState$(state$: Store<any>): Observable<CartState> {
  return state$.select(state => state.cart);
}

export function isRequestPending$(state$: Store<any>): Observable<boolean> {
  return state$.select(state => state.cart.requestPending);
}
