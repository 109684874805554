export const localeBaseEn = {
  'lang': 'en',
  'data': {
    'CLIENT': {
      'NAME': 'Local Test Client'
    },
    'BASE': {
      'TITLE': 'Tickets',
      'LOADING': 'Loading Application...',
      'ABORT': 'Cancel',
      'REALLY_QUIT': 'Do you really want to leave?',
      'CANCEL': 'Cancel',
      'NEXT': 'Next',
      'PREVIOUS': 'Back',
      'RESTART': 'Reload',
      'ALERT_DISMISS': 'Dismiss',
      'ALERT_RETRY_LAST': 'Continue',
      'EXIT': 'Exit',

      'IMPRESSUM': 'Legal',
      'AGB': 'Terms of Use',
      'DATENSCHUTZ': 'Privacy Policy',

      'STEPS': {
        'SEATING': 'Seats',
        'PAYMENT': 'Payment',
        'CONFIRMATION': 'Confirmation'
      },

      'ERROR': {
        'TITLE': 'An unexpected Error occured',
        'DETAILS': 'This should not have happened',
        'CODE': 'Code: {{code}}',
        'SOCKET-X': 'Connection Error. Please check your internet connection and click an \'Connect\'.',
        'RECONNECT': 'Connect',
        'RESTART': 'Restart',
        'ALERT_RETRY_LAST': 'Continue',
        'RETRY_INFO': 'Another try in {{seconds}} seconds',
        'RETRY_PROGRESS': '({{attempt}} / {{max}})',
        'RETRY_DONE': 'Please try again later',
        'EXIT': 'Cancel',
        'BFE-VA-1': {
          'TITLE': 'Confirmation failed',
          'MESSAGE': 'The hash is no longer valid or already used'
        },
        'HTTP': {
          'TITLE': 'Connection Error',
          'CONTENT': 'We have lost connection. Please check your internet connection and retry.'
        },
        'BFE-6': {
          'TITLE': 'Something went wrong',
          'MESSAGE': 'There was a technical Issue Please try again. If the problem still remains, please make sure to reload the page manually.'
        },
      }
    },

    'FOOTER': {
      'IMPRINT': 'Impressum',
      'TERMS_AND_CONDITIONS': 'AGB',
      'DATA_PROTECTION_GUIDELINES': 'Datenschutz',
    },

    'PAGE_NOT_FOUND': {
      'TITLE': 'Oops!',
      'SUBTITLE': '404 Not Found',
      'DETAILS': 'Sorry, an error has occured, Requested page not found!',
      'GO_HOME': 'Take Me Home',
      'CONTACT_SUPPORT': 'Contact Support'
    }
  }
};
