import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

export enum LegalModalType {
  IMPRINT = 'IMPRINT',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  DATA_PROTECTION_GUIDELINES = 'DATA_PROTECTION_GUIDELINES',
  NONE = 'NONE'
}

@Injectable()
export class LegalService {

  private _showLegalModal: BehaviorSubject<LegalModalType>;

  constructor() {
    // Trigger modal open
    this._showLegalModal = new BehaviorSubject(LegalModalType.NONE);
  }

  close() {
    this._showLegalModal.next(LegalModalType.NONE);
  }

  showLegalModal$(): Observable<LegalModalType> {
    return this._showLegalModal.asObservable();
  }

  show(type: LegalModalType): boolean {
    if (this._showLegalModal.getValue() === LegalModalType.NONE) {
      this._showLegalModal.next(type);
      return true;
    }
    return false;
  }

}
