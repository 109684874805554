import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './containers/app';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found';
import {SplashComponent} from './components/splash/splash.component';
import {ErrorComponent} from './components/error/error.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {FooterComponent} from './components/footer/footer.component';
import {RouterModule} from '@angular/router';
import {FeStarterSharedModule, TranslationLoaderService} from 'fe-starter-shared';
import {german} from './i18n/client/de';
import {english} from './i18n/client/en';
import {StepNavigationComponent} from './components/step-navigation/step-navigation.component';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {TitleService} from './services/title.service';
import {LegalService} from './services/legal.service';
import {LegalModalComponent} from './components/legal/legal.modal.component';
import {AcrossTabsChildService} from './services/across-tabs.service';
import {BrowserCheckModalComponent} from './components/browser-check/browser-check-modal.component';

const COMPONENTS = [
  AppComponent,
  PageNotFoundComponent,
  SplashComponent,
  ErrorComponent,
  NavbarComponent,
  StepNavigationComponent,
  FooterComponent,
  LegalModalComponent
];

const MODALS = [
  BrowserCheckModalComponent
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FeStarterSharedModule,
    NgbModule,
    SimpleNotificationsModule
  ],
  declarations: [
    ...COMPONENTS,
    ...MODALS
  ],
  exports: [
    ...COMPONENTS
  ],
  entryComponents: [
    ...MODALS
  ]
})
export class CoreModule {

  constructor(private translationLoader: TranslationLoaderService) {
    /* Load translations in each module/component as needed */
    this.translationLoader.loadTranslations(german, english);
  }

  static forRoot() {
    return {
      ngModule: CoreModule,
      providers: [
        TitleService,
        LegalService,
        AcrossTabsChildService
      ]
    };
  }
}
