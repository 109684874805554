import {Action} from '@ngrx/store';
import {Process} from '../../models/process.model';
import {ApplicationModes} from '../../enums/application-modes.enum';
import {RequestRecord} from '../../models/socket-request.interface';
import {SocketCloseReasons} from '../../enums/socket-request-types.enum';

export class SocketsActions {
  static CONNECT = '[Sockets] Connect';
  static CONNECTED = '[Sockets] Connected';

  static CONNECT_TO_SOCKETS = '[Sockets] Connect to Sockets/Topics';
  static CONNECT_TO_MAIN_SOCKETS = '[Sockets] Connect to Main Topics (Process / Session / Performance)';
  static CONNECT_TO_TRANSACTION_SOCKET = '[Sockets] Connect to Transaction Sockets';

  static CLOSE_CONNECTION = '[Sockets] CLOSE_CONNECTION';
  static CLOSED = '[Sockets] CLOSED';

  static ADD_REQUEST = '[Sockets] ADD REQUEST';
}

export class SocketsConnect implements Action {
  readonly type = SocketsActions.CONNECT;
}

export class SocketsConnected implements Action {
  readonly type = SocketsActions.CONNECTED;

  constructor(public payload: ApplicationModes) {
  }
}

export class SocketsConnectToSockets implements Action {
  readonly type = SocketsActions.CONNECT_TO_SOCKETS;

  constructor(public payload: Process) {
  }
}

export class SocketsConnectToMainSockets implements Action {
  readonly type = SocketsActions.CONNECT_TO_MAIN_SOCKETS;

  constructor(public payload: Process) {
  }
}

export class SocketsConnectToTransactionSocket implements Action {
  readonly type = SocketsActions.CONNECT_TO_TRANSACTION_SOCKET;
}

export class SocketsCloseConnection implements Action {
  readonly type = SocketsActions.CLOSE_CONNECTION;

  constructor(public payload: SocketCloseReasons = SocketCloseReasons.SHUTDOWN) {
  }
}

export class Closed implements Action {
  readonly type = SocketsActions.CLOSED;
}

export class AddRequest implements Action {
  readonly type = SocketsActions.ADD_REQUEST;

  constructor(public payload: Partial<RequestRecord>) {
  }
}
