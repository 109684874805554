import {Store} from '@ngrx/store';
import {RootState} from '../reducers';
import {TransactionComplete} from '../../models/process.model';
import {SpaceSelection} from '../../models/space-selection.interface';
import {PriceInfo, PriceRow, VoucherInfo} from '../../models/price-info.interfaces';
import {ProcessState} from './process.reducers';
import {Observable} from 'rxjs';
import {PaymentMethods} from '../../models/payment.models';
import {map, withLatestFrom} from 'rxjs/operators';
import {environment} from '../../environments/environment';

export function getProcess$(state$: Store<RootState>): Observable<ProcessState> {
  return state$.select(state => state.shared.process);
}

export function getProcessId$(state$: Store<RootState>): Observable<string> {
  return state$.select(state => state.shared.process.id);
}

export function getPriceInfo$(state$: Store<RootState>): Observable<PriceInfo> {
  return state$.select(state => state.shared.process.priceInfo);
}

export function getWaitingPosition$(state$: Store<RootState>): Observable<number> {
  return state$.select(state => state.shared.process.waitingPosition);
}

export function getSelectedSpaces$(state$: Store<RootState>): Observable<SpaceSelection[]> {
  return state$.select(state => state.shared.process.selectedSpaces);
}

export function getComplete$(state$: Store<RootState>): Observable<TransactionComplete> {
  return state$.select(state => state.shared.process.completion);
}

export function getSelectionToken$(state$: Store<RootState>): Observable<string> {
  return state$.select(state => state.shared.process.selectionToken);
}

export function isSpaceSelectionPending$(state$: Store<RootState>): Observable<boolean> {
  return state$.select(state => state.shared.process.spaceSelectionRequestPending);
}

export function isCartUpdateRequestPending$(state$: Store<RootState>): Observable<boolean> {
  return state$.select(state => state.shared.process.cartUpdateRequestPending);
}

export function getAppliedVouchers$(state$: Store<RootState>): Observable<VoucherInfo[]> {
  return state$.select(state => {
    let appliedVouchers: VoucherInfo[] = [];
    if (state.shared.process.priceInfo != null) {
      if (state.shared.process.priceInfo.voucherValueInfo) {
        appliedVouchers = [
          ...appliedVouchers,
          ...state.shared.process.priceInfo.voucherValueInfo
        ];
      }
      if (state.shared.process.priceInfo.priceTicketMappings != null) {
        state.shared.process.priceInfo.priceTicketMappings.forEach((mapping: PriceRow) => {
          if (mapping.ticketPricesInfos.voucherInfoList && mapping.ticketPricesInfos.voucherInfoList.length > 0) {
            appliedVouchers = [
              ...appliedVouchers,
              ...mapping.ticketPricesInfos.voucherInfoList
            ];
          }
        });
      }
    }

    return appliedVouchers;
  });
}

export function isFullyPayedWithVouchers$(state$: Store<RootState>): Observable<boolean> {
  return state$.pipe(
    withLatestFrom(
      getPriceInfo$(state$),
      getAppliedVouchers$(state$),
      (state, priceInfo, appliedVouchers) => ({
        priceInfo,
        appliedVouchers
      })
    )
  ).pipe(
    map((state) => {
      return (state.priceInfo.fullAmountWithFee === 0 && state.appliedVouchers.length > 0);
    })
  );
}

export function getPaymentMethods$(state$: Store<RootState>): Observable<PaymentMethods> {
  return state$.select(state => state.shared.process.allowedPayments as PaymentMethods);
}

export function getAvailablePaymentMethods$(state$: Store<RootState>): Observable<string[]> {
  return state$.select(state => {
      return Object.keys(state.shared.process.allowedPayments.paymentMethods)
        .filter(
          name => environment.ignoredPaymentMethods.indexOf(name) === -1
        );
    }
  );
}

export function getAvailablePaymentMethodsForSalesCounter$(state$: Store<RootState>): Observable<string[]> {
  return state$.select(state => {
      return Object.keys(state.shared.process.allowedPayments.paymentMethods)
        .filter(
          name => environment.ignoredPaymentMethodsForSalesCounter.indexOf(name) === -1
        );
    }
  );
}
