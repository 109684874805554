<ng-container *ngIf="isRequestPending">
  <c360-loading-bar message="{{'PAYMENT.LOADING' | translate}}"></c360-loading-bar>
</ng-container>

<ng-container *ngIf="!isRequestPending && priceInfo">
  <p>
    <span *ngIf="data.selectedPaymentMethod"><i class="{{data.selectedPaymentMethod.icon}}" aria-hidden="true"></i> {{'PAYMENT.PAYMENT_METHODS.' + data.selectedPaymentMethod.method | translate}}</span><br/>
    <b class="mt-4" *ngIf="skipSaleFee" [translate]="'PAYMENT.TOTAL'" [translateParams]="{amount: priceInfo.fullAmount  | localeCurrency:'symbol':'1.2'}"></b>
    <b class="mt-4" *ngIf="!skipSaleFee" [translate]="'PAYMENT.TOTAL'" [translateParams]="{amount: priceInfo.fullAmountWithFee  | localeCurrency:'symbol':'1.2'}"></b>
  </p>
  <div *ngIf="useStoredPayment">
    <div>
      <span [translate]="'PAYMENT.STORED.CREDIT_CARD.TITLE'"></span><br/>
      <b>{{data.paymentInfo.providerTypeIdMasked}}</b><br/>
      <button class="btn btn-secondary btn-block mt-3 py-3" (click)="clearStoredPayment()" [translate]="'PAYMENT.STORED.CREDIT_CARD.CHANGE'"></button>
    </div>
  </div>

  <form role="form"
        *ngIf="paymentForm && !useStoredPayment"
        (ngSubmit)="submitForm()"
        [formGroup]="paymentForm">
    <div>
      <label for="cardpan" [translate]="'PAYMENT.FORMS.CREDIT_CARD.LABEL_CARDPAN'"></label><br/>
      <span id="cardpan" class="inputIframe"></span>
    </div>
    <div>
      <label for="cardcvc2" [translate]="'PAYMENT.FORMS.CREDIT_CARD.LABEL_CVC2'"></label><br/>
      <span id="cardcvc2" class="inputIframe"></span>
    </div>
    <div>
      <label for="expireInput" [translate]="'PAYMENT.FORMS.CREDIT_CARD.LABEL_EXPIRY'"></label><br/>
      <span id="expireInput" class="inputIframe">
      <span id="cardexpiremonth"></span>
      <span id="cardexpireyear"></span>
    </span>
    </div>

    <div id="errorOutput" class="c360-alert-leftborder c360-alert-danger mb-3 pl-2"></div>
    <div *ngIf="showErrorRequiredFields" role="alert" class="alert alert-warning text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.CREDIT_CARD.REQUIRED_FIELDS'"></div>
    <div *ngIf="showErrorNotValid" role="alert" class="alert alert-warning text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.CREDIT_CARD.NOT_VALID'"></div>
    <div *ngIf="data.paymentInfo.status === 'REDIRECT_ABORTED'" role="alert" class="alert alert-warning text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.CREDIT_CARD.' + data.paymentInfo.status"></div>
    <div *ngIf="data.paymentInfo.status === 'REDIRECT_FAILED'" role="alert" class="alert alert-danger text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.CREDIT_CARD.' + data.paymentInfo.status"></div>

    <span [translate]="'BASE.FORM.REQUIRED_FIELDS'"></span>

    <div *ngIf="externalPaymentWaitingTime" role="alert" class="alert alert-success text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.CREDIT_CARD.WAITING_TIME'" [translateParams]="{time: externalPaymentWaitingTime}"></div>

    <button type="submit" class="btn btn-block btn-primary btn-lg py-3" [translate]="'PAYMENT.FORMS.CREDIT_CARD.SUBMIT'"></button>
  </form>

<!--  <div *ngIf="paymentForm && !useStoredPayment" id="paymentform"></div>-->
</ng-container>
