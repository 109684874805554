import {localeBaseDe} from '../de';
import {mergeDeep} from 'fe-starter-shared';

const localeClientDe = {
  data: {
    TICKETING: {
      HEADER: 'Ticketauswahl'
    }
  }
};

export const german = mergeDeep({}, localeClientDe, localeBaseDe);
