import {Component, OnInit} from '@angular/core';
import * as Bowser from 'bowser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BrowserCheckModalComponent} from '../browser-check/browser-check-modal.component';

@Component({
  selector: 'c360-splash',
  templateUrl: './splash.component.html',
  providers: []
})

export class SplashComponent implements OnInit {

  constructor(private _modalService: NgbModal) {
  }

  ngOnInit(): void {
    const browser = Bowser.getParser(window.navigator.userAgent);

    const isValidBrowser = browser.satisfies({
      chrome: '>=76',
      firefox: '>=68',
      edge: '>=79',
      safari: '>=11',
      opera: '>=55'
    });

    if (!isValidBrowser) {
      this._modalService.open(BrowserCheckModalComponent, {
        backdrop : 'static',
        keyboard : false,
        windowClass: 'browser-check-modal-container'
      });
    }
  }
}
