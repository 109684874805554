import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {PaymentFormComponent, PaymentFormData} from '../payment-form.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {getPriceInfo$, isRequestPending$, PaymentInfo, PriceInfo, ValidationError} from 'fe-starter-shared';
import {PaymentFormResult} from '../../../models/payment-form-result.model';
import {Subscription} from 'rxjs';
import {getCartState$} from '../../../../cart/store';

@Component({
  templateUrl: './direct-debit.component.html'
})
export class DefaultDirectDebitComponent extends PaymentFormComponent implements OnInit, OnDestroy {
  data: PaymentFormData;
  validationErrors: ValidationError[] = [];
  public onValid: EventEmitter<PaymentFormResult> = new EventEmitter<PaymentFormResult>();

  _result: PaymentInfo = new PaymentInfo();
  _subscription: Subscription;

  paymentForm: FormGroup;
  public useStoredPayment: boolean;
  public isRequestPending: boolean;
  public priceInfo: PriceInfo;
  public skipSaleFee: boolean;

  constructor(private _store: Store<any>,
              private _cd: ChangeDetectorRef,
              private _formBuilder: FormBuilder) {
    super();
    this._subscription = new Subscription();
    this._subscription.add(
      this._store
        .pipe(isRequestPending$)
        .subscribe(isRequestPending => this.isRequestPending = isRequestPending)
    ).add(
      this._store.pipe(getCartState$)
        .subscribe(cartState => this.skipSaleFee = cartState.skipSaleFee)
    ).add(
      this._store.pipe(getPriceInfo$)
        .subscribe((priceInfo: PriceInfo) => this.priceInfo = priceInfo)
    );
  }

  ngOnInit(): void {
    this.initForms();
    this.initPaymentMethod();
  }

  ngOnDestroy() {
    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  private hasStoredPayment(): boolean {
    return this.data.paymentInfo != null &&
      this.data.paymentInfo.providerTypeIdMasked != null &&
      this.data.paymentInfo.providerTypeCustomerName != null;
  }

  private initPaymentMethod(reset: boolean = false) {
    if (!reset && this.hasStoredPayment()) {
      this.useStoredPayment = true;
      this.onValid.emit({
        useStoredPayment: this.useStoredPayment,
        bookDirectly: false,
        payment: {
          ...this._result
        }
      });
    } else {
      this.useStoredPayment = false;
      this._result = {
        ...new PaymentInfo(),
        provider: this.data.paymentInfo.provider,
        providerType: this.data.paymentInfo.providerType,
        providerTypeCustomerName: (this.data.customer != null) ? this.data.customer.firstName + ' ' + this.data.customer.lastName : null,
      };
      this.paymentForm.patchValue(this._result);
    }
  }

  private initForms(): void {
    this.paymentForm = this._formBuilder.group(
      {
        providerTypeCustomerName: [
          this._result.providerTypeCustomerName,
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ],
        providerTypeId: [
          this._result.providerTypeId,
          [
            Validators.required
          ]
        ]
      }
    );

    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  public submitForm() {
    this.paymentForm.controls.providerTypeId.setErrors(null);
    this.paymentForm.controls.providerTypeCustomerName.setErrors(null);
    if (this.paymentForm.valid) {
      this.onValid.emit({
        useStoredPayment: this.useStoredPayment,
        bookDirectly: false,
        payment: {
          ...this._result,
          providerTypeId: this.paymentForm.value.providerTypeId,
          providerTypeCustomerName: this.paymentForm.value.providerTypeCustomerName
        }
      });
    } else {
      this.onValid.emit(null);
    }
  }

  public clearStoredPayment() {
    this.initPaymentMethod(true);
  }

  setData(data: PaymentFormData): void {
    this.data = data;
    try {
      this._cd.detectChanges();
    } catch (e) {
      //
    }
  }

  public setValidationErrors(validationErrors: ValidationError[]): void {
    this.validationErrors = validationErrors;

    if (this.validationErrors != null) {
      this.validationErrors.forEach(validationError => {
        if (this.paymentForm.controls[validationError.field] != null) {
          this.paymentForm.controls[validationError.field].setErrors({remote: {invalid: validationError.message}});
        }
      });
    }
  }
}
