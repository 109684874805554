export const localeBaseEn = {
  lang: 'en',
  data: {
    LOGIN: {
      WELCOME_BAR: {
        WELCOME: 'Willkommen',
        LOGOUT_BUTTON: 'Ausloggen',
      },
      LOGIN_FORM: {
        INTRO: '',
        LABEL_EMAIL: 'E-Mail',
        LABEL_PASSWORD: 'Password',
        NOT_ME: 'Das bin ich nicht',
        LOGIN_BUTTON: 'Anmelden'
      },
      GUEST_FORM: {
        INTRO: 'Buchung als Gast.',
        LABEL_NAME: 'Name',
        LOGIN_BUTTON: 'Fortfahren'
      },
      PASSWORD_RESET: {
        QUESTION: 'Passwort vergessen?',
        TITLE: 'Passwort vergessen',
        INTRO: 'Tragen Sie hier Ihre E-Mail-Adresse ein mit der Sie bei uns registriert sind.' +
          'Sie erhalten dann ein neues Passwort zugesendet.',
        LABEL_EMAIL: 'E-Mail',
        SUBMIT: 'Passwort anfordern',
        CONFIRMATION: 'Wir haben Ihnen eine E-Mail mit weiteren Details zum zurücksetzen Ihres Passworts geschickt.',
        BACK_TO_LOGIN: 'Zurück zum Login'
      }
    },
    PERSONAL_DETAILS: {}
  }
};
