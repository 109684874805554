import {Action} from '@ngrx/store';
import {PaymentInfoActions} from './payment-info.actions';
import {PaymentInfo} from '../../models/payment-info.model';

const initialState: PaymentInfo = new PaymentInfo();

/**
 * Unsafe action
 */
interface UnsafeAction extends Action {
  payload?: any;
}

/**
 * Payment info
 */
export function paymentInfo(
  state: PaymentInfo = initialState,
  action: UnsafeAction
): PaymentInfo {
  switch (action.type) {

    case PaymentInfoActions.PAYMENT_INFO_INIT_RECEIVED:
    case PaymentInfoActions.PAYMENT_INFO_UPDATE_RECEIVED:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
}
