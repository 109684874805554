export const localeBaseDe = {
  lang: 'de',
  data: {
    CART: {
      TITLE: 'Warenkorb',
      TICKETS: 'Tickets',
      VOUCHER_FORM: {
        TITLE: 'Gutscheincode:',
        LABEL_VOUCHER: 'Gutscheincode',
        REDEEM_BUTTON: 'Gutschein anwenden'
      },
      FEE: 'Systemgebühr',
      EMPTY: {
        TITLE: 'Sitzplatzauswahl',
        SUBTITLE: 'Bitte wählen Sie Ihre Sitzplätze aus'
      },
      LIST: {
        TITLE: 'Sitzplatzauswahl',
        SUBTITLE: 'Anzahl der Tickets insgesamt',
        TOTAL: 'Gesamt',
        TOTAL_FEE: 'Gesamtpreis enthält {{vatSet}}% USt'
      },
      INLINE: {
        TITLE: 'Warenkorb',
        ROW: 'Reihe: {{row}}',
        ROW_POSITIONS: 'Plätze: {{rowPositions}}',
      },
      REQUEST_PENDING: 'Warenkorb wird aktualisiert',
    },
  }
};
