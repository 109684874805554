<!--suppress AngularUndefinedTag -->
<c360-loading-bar message="{{ 'SEATING.LOADING' | translate }}" *ngIf="isLoading"></c360-loading-bar>
<!--suppress AngularUndefinedBinding -->
<div class="text-center" #renderer
     (press)="onPress($event)"
     (tap)="onTap($event)"
     (pan)="onPan($event)"
     (panstart)="onPanStart($event)"
     (pinch)="onPinch($event)"
     (panend)="onPanEnd($event)"></div>
