import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import * as applicationActions from '../../core/store/application/application.actions';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {ApplicationState, getApplication$} from '../../core/store/application';

@Injectable()
export class InitGuard implements CanActivateChild {

  private applicationState$ = this.store.pipe((getApplication$));

  constructor(private router: Router,
              private store: Store<any>) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.applicationState$.pipe(map((applicationState: ApplicationState) => {
      // Deny access by Default=> Init required
      let result = false;

      if (applicationState.initComplete || childRoute.routeConfig.path === 'init') { // Init complete or Init is requested
        // Allow on InitComplete or Init route request
        result = true;

      } else if (childRoute.routeConfig.path !== '') { // Some other Child Module is requested?
        // Store requested url for redirect on InitComplete
        this.store.dispatch(
          new applicationActions.SetInitialUrl(state.url)
        );

        // Redirect to Init
        this.router.navigate([
          state.url.replace(childRoute.routeConfig.path, 'init')
        ]);

      } else { // Empty RouteConfig can be ignored
        result = true;
      }

      return result;
    }));
  }
}
