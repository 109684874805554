import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {PaymentMethod, RootState} from 'fe-starter-shared';
import {getPaymentMethods$} from 'fe-starter-shared';
import {Subscription} from 'rxjs';
import {PaymentMethods} from 'fe-starter-shared';

@Component({
  selector: 'c360-payment-options',
  templateUrl: './payment-options.component.html'
})
export class PaymentOptionsComponent implements OnInit, OnDestroy {

  private _paymentMethods$ = this._store.pipe(getPaymentMethods$);

  private _subscription: Subscription;
  public paymentMethods: {
    [p: string]: PaymentMethod;
  };

  constructor(private _store: Store<RootState>) {
  }

  get paymentMethods$(): any {
    return this._paymentMethods$;
  }

  ngOnInit(): void {
    this._subscription = new Subscription();
    this._subscription.add(
      this._paymentMethods$.subscribe((paymentMethods: PaymentMethods) => {
        this.paymentMethods = paymentMethods.paymentMethods;
      })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
