export const localeBaseDe = {
  lang: 'de',
  data: {
    LOGIN: {
      WELCOME_BAR: {
        WELCOME: 'Willkommen',
        LOGOUT_BUTTON: 'Abmelden',
      },
      LOGIN_FORM: {
        INTRO: '',
        INTRO_AUTS5: 'Zu der eingegeben E-Mail-Adresse existiert bereits ein Account. ' +
          'Melden Sie sich an, um fortzufahren oder wählen Sie eine andere E-Mail-Adresse.',
        LABEL_EMAIL: 'E-Mail-Adresse',
        LABEL_PASSWORD: 'Passwort',
        NOT_ME: 'Das bin ich nicht',
        LOGIN_BUTTON: 'Anmelden',
        LOGOUT_BUTTON: 'Abmelden',
        CHANGE_EMAIL_ADDRESS_BUTTON: 'E-Mail-Adresse ändern',
      },
      GUEST_FORM: {
        CHANGE_EMAIL_ADDRESS_BUTTON: 'Zurück',
        BOOK_AS_GUEST_BUTTON: 'Als Gast forfahren',
        REGISTRATION_BUTTON: 'Weiter'
      },
      PERSONAL_FORM: {
        LABEL_EMAIL: 'E-Mail-Adresse',
        BACK_BUTTON: 'Zurück',
        NEXT_BUTTON: 'Weiter'
      },
      PASSWORD_RESET: {
        QUESTION: 'Passwort vergessen?',
        TITLE: 'Passwort vergessen',
        INTRO: 'Wenn Sie bereits ein Benutzerkonto haben, jedoch das Passwort nicht zur Hand haben, ' +
          'können Sie Ihr Passwort hier zurücksetzen.',
        INTRO_BOOKING: 'Sie haben Ihr Passwort grade nicht zur Hand, dann können Sie es hier zurücksetzen.',
        LABEL_EMAIL: 'E-Mail',
        PLACEHOLDER_EMAIL: 'E-Mail-Adresse',
        SUBMIT: 'Weiter',
        CONFIRMATION: 'Wir haben Ihnen eine E-Mail mit weiteren Anweisungen an {{emailAddress}} geschickt.',
        BACK_TO_LOGIN: 'Zurück zur Anmeldung'
      }
    },
    PERSONAL_DETAILS : {
      REQUEST_PENDING: 'Persönliche Daten werden hinterlegt',
      FORM: {
        LABEL_GENDER: 'Anrede*:',
        LABEL_MAIL: 'E-Mail-Adresse*:',
        LABEL_MAIL_REPEAT: 'E-Mail-Adresse wiederholen*:',
        LABEL_PASSWORD: 'Passwort*:',
        LABEL_PASSWORD_REPEAT: 'Passwort wiederholen*:',
        LABEL_FIRSTNAME: 'Vorname*:',
        LABEL_LASTNAME: 'Nachname*:',
        LABEL_STREET: 'Straße*:',
        LABEL_HOUSENUMBER: 'Hausnummer*:',
        LABEL_COUNTRY: 'Land*:',
        LABEL_ZIP: 'PLZ*:',
        LABEL_CITY: 'Ort*:',
        LABEL_PHONE: 'Telefonnummer*:',
        PLACEHOLDER_GENDER: '--- Anrede auswählen ---',
        PLACEHOLDER_MAIL: 'E-Mail-Adresse',
        PLACEHOLDER_MAIL_REPEAT: 'E-Mail-Adresse',
        PLACEHOLDER_PASSWORD: 'Passwort',
        PLACEHOLDER_PASSWORD_REPEAT: 'Passwort wiederholen',
        PLACEHOLDER_FIRSTNAME: 'Vorname',
        PLACEHOLDER_LASTNAME: 'Nachname',
        PLACEHOLDER_STREET: 'Straße',
        PLACEHOLDER_HOUSENUMBER: 'Hausnummer',
        PLACEHOLDER_COUNTRIES: '--- Land auswählen ---',
        PLACEHOLDER_ZIP: 'PLZ',
        PLACEHOLDER_CITY: 'Ort',
        PLACEHOLDER_PHONE: 'Telefonnummer',
        REQUIRED_INPUTS: '* Pflichtfelder',
        SUBMIT: 'Weiter',
        INVALID: 'Bitte füllen Sie alle markierten Pfilchtfelder aus.'
      },
      GENDERS : {
        FEMALE : 'Frau',
        MALE: 'Herr'
      },
      COUNTRIES: {
        ALBANIA: 'Albanien',
        ANDORRA: 'Andorra',
        ARMENIA: 'Armenien',
        AUSTRIA: 'Österreich',
        AZERBAIJAN: 'Aserbaidschan',
        BELARUS: 'Weißrussland',
        BELGIUM: 'Belgien',
        BOSNIA_AND_HERZEGOVINA: 'Bosnien und Herzegowina',
        BULGARIA: 'Bulgarien',
        CROATIA: 'Kroatien',
        CYPRUS: 'Zypern',
        CZECH_REPUBLIC: 'Tschechische Republik',
        DENMARK: 'Dänemark',
        ESTONIA: 'Estland',
        FINLAND: 'Finnland',
        FRANCE: 'Frankreich',
        GEORGIA: 'Georgien',
        GERMANY: 'Deutschland',
        GREECE: 'Griechenland',
        HUNGARY: 'Ungarn',
        ICELAND: 'Island',
        IRELAND: 'Irland',
        ITALY: 'Italien',
        KAZAKHSTAN: 'Kasachstan',
        KOSOVO: 'Kosovo',
        LATVIA: 'Lettland',
        LIECHTENSTEIN: 'Liechtenstein',
        LITHUANIA: 'Litauen',
        LUXEMBOURG: 'Luxemburg',
        MACEDONIA: 'Mazedonien',
        MALTA: 'Malta',
        MOLDOVA: 'Moldawien',
        MONACO: 'Monaco',
        MONTENEGRO: 'Montenegro',
        NETHERLANDS: 'Niederlande',
        NORWAY: 'Norwegen',
        POLAND: 'Polen',
        PORTUGAL: 'Portugal',
        ROMANIA: 'Rumänien',
        RUSSIA: 'Russland',
        SAN_MARINO: 'San Marino',
        SERBIA: 'Serbien',
        SLOVAKIA: 'Slowakei',
        SLOVENIA: 'Slowenien',
        SPAIN: 'Spanien',
        SWEDEN: 'Schweden',
        SWITZERLAND: 'Schweiz',
        TURKEY: 'Türkei',
        UKRAINE: 'Ukraine',
        UNITED_KINGDOM: 'Vereinigtes Königreich',
        VATICAN_CITY: 'Vatikan STADT',
      }
    },
  }
};
