import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {getCustomer$} from 'fe-starter-shared';
import {getApplicationMode$} from '../../core/store/application';
import {ApplicationModes} from 'fe-starter-shared';
import {User} from 'fe-starter-shared';

@Injectable()
export class IsAuthorizedGuard implements CanActivate {

  private _customer: User;
  private _mode: ApplicationModes;

  private _checkoutRoutes = /payment/ig;

  constructor(private router: Router, private _store: Store<any>) {
    this._store.pipe((getApplicationMode$)).subscribe(mode => this._mode = mode);
    this._store.pipe((getCustomer$)).subscribe(customer => this._customer = customer);
  }

  canActivate(route: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean {
    switch (this._mode) {
      case ApplicationModes.CONFIRMATION:
        return this.canActivateWithinConfirmation(route, routerStateSnapshot);
      default:
        return this.canActivateWithinCheckout(route, routerStateSnapshot);
    }
  }

  canActivateWithinConfirmation(route: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean {
    return true;
  }

  canActivateWithinCheckout(route: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean {
    if (this._customer != null && this._customer.type != null) {
      return true;
    } else {
      this.router.navigate([
        routerStateSnapshot.url.replace(this._checkoutRoutes, 'info')
      ]);
      return false;
    }
  }
}
