import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {cart, CartEffects} from './store';
import {Store, StoreModule} from '@ngrx/store';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {FeStarterSharedModule, TranslationLoaderService} from 'fe-starter-shared';
import {german} from './i18n/client/de';
import {english} from './i18n/client/en';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollapsableCartTitleComponent} from './components/collapsable-cart/collapsable-cart-title.component';
import {CollapsableCartListComponent} from './components/collapsable-cart/collapsable-cart-list.component';
import {CollapsableCartComponent} from './components/collapsable-cart/collapsable-cart.component';
import {InlineCartComponent} from './components/inline-cart/inline-cart.component';
import {CollapsableCartEmptyComponent} from './components/collapsable-cart/collapsable-cart-empty.component';
import {TicketingModule} from '../ticketing/ticketing.module';
import {NgxTrimDirectiveModule} from 'ngx-trim-directive';
import {CUSTOM_ERROR_MESSAGES, NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {CustomErrorFactory} from '../../../core/validators/custom.errors';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    TicketingModule,
    FeStarterSharedModule,
    NgbModule,
    /**
     * StoreModule.forFeature is used for composing state
     * from feature modules. These modules can be loaded
     * eagerly or lazily and will be dynamically added to
     * the existing state.
     */
    StoreModule.forFeature('cart', cart),

    /**
     * Effects.forFeature is used to register effects
     * from feature modules. Effects can be loaded
     * eagerly or lazily and will be started immediately.
     *
     * All Effects will only be instantiated once regardless of
     * whether they are registered once or multiple times.
     */
    EffectsModule.forFeature([CartEffects]),
    NgxTrimDirectiveModule,
    NgBootstrapFormValidationModule
  ],
  declarations: [
    CollapsableCartComponent,
    CollapsableCartTitleComponent,
    CollapsableCartListComponent,
    CollapsableCartEmptyComponent,
    InlineCartComponent
  ],
  exports: [
    CollapsableCartComponent,
    InlineCartComponent
  ]
})
export class CartModule {
  constructor(private _store: Store<any>,
              private _translationLoader: TranslationLoaderService) {
    /* Load translations in each module/component as needed */
    this._translationLoader.loadTranslations(german, english);
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CartModule,
      providers: [
        {
          provide: CUSTOM_ERROR_MESSAGES,
          useFactory: CustomErrorFactory,
          deps: [TranslateService],
          multi: true
        }
      ]
    };
  }
}
