import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {getLayout$} from '../../store/layout';
import {Step} from '../../models/step.model';

@Component({
  selector: 'c360-step-navigation',
  templateUrl: './step-navigation.component.html',
  providers: []
})

export class StepNavigationComponent implements OnInit, OnDestroy {

  @Input() steps: Step[] = null;
  @Output() onStepSelected: EventEmitter<string> = new EventEmitter<string>();
  public layout = this._store.pipe((getLayout$));

  /** Constructor */
  constructor(private _store: Store<any>) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  onStepTitleClick(stepName) {
    this.onStepSelected.emit(stepName);
  }

  getActiveStepIndex() {
    return this.steps.findIndex((step) => {
      return step.active;
    });
  }
}
