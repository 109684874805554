// Actual config of our Shared Module needs to be passed when bootstrapping
// use SharedModule.forRoot() to pass data

export const environment = {
  production: false,
  hasDevTools: true,
  useMocks: false,

  clientType: 'BOOKING',

  logLevel: 0,

  client: {
    name: 'Cinema360'
  },

  socketRequestTimeout: 7500,
  maxSocketReconnectAttempts: 60,

  locale: 'de-DE', // 'de-DE' for Germany, 'fr-FR' for France ...
  currency: 'EUR',

  languages: ['de'],
  languageDir: './i18n/client',
  defaultLanguage: 'de',

  urls: {
    website: 'https://www.krankikom.de',
    cms: 'http://localhost:4500',
    cmsConfirmation: '/counter/confirmation/',
    cmsSchedule: '/counter/schedule/',
    services: 'https://cinema360-booking-ger-krankikom-gmbh.krankikom.de/gateway/',
    process: 'booking-session/process',
    webSocket: 'booking-session/ws',
    auth: 'auth/oauth/token',
    passwordReset: 'auth/users/custom/passwordReset',
    verifyAccount: 'auth/users/custom/registration',
    changeEmailAddress: 'auth/users/custom/changeEmailAddress',
    users_search: 'auth/users/search/findByExternalId',
    users_patch: 'auth/users',
    users_change_email: 'auth/users/custom/changeEmailAddress',
    transaction_searches: 'booking-transaction/transactionSearches/search/findAllByCustomerUserExternalId',
    transaction_refund: 'booking-transaction/transactions/custom/refund'
  },

  oauth: {
    clientId: 'ddc48388-e16c-4e00-be66-dcb84928cb4e',
    grant_type_login: 'password',
    grant_type_refresh: 'refresh_token'
  },

  isQuickbookingSupported: false,

  seating: {
    animations: {
      select: {
        type: 'flip',
        config: {
          speed: 0.5
        },
      },
      unselect: {
        type: 'flip',
        config: {
          speed: 1.5
        },
      }
    }
  },

  tracking: {
    ids: ['GTM-WHWXMWJ'],
    dataLayerName: 'dataLayer',
    language: 'en',
    currency: 'EUR'
  },
  userPermissionOrder: [
    'ROLE_ADMIN',
    'ROLE_MANDATOR',
    'ROLE_LOCATION',
    'ROLE_SALES_COUNTER',
    'ROLE_PRE_SALES_COUNTER',
    'ROLE_TICKET_VALIDATOR',
    'ROLE_CUSTOMER',
  ],
  media: {
    poster: '/assets/images/placeholder/media_poster.png',
    design: '/assets/images/placeholder/media_design.png'
  },

  ignoredPaymentMethodsForSalesCounter: [
    'VOUCHER',
    'DIRECT_DEBIT'
  ],
  ignoredPaymentMethods: [
    'VOUCHER'
  ]
};
