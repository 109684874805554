import {ChangeDetectorRef, Component, EventEmitter, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {PaymentFormComponent, PaymentFormData} from '../payment-form.component';
import {PaymentFormResult} from '../../../models/payment-form-result.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  getPaymentCrefopayConfig$,
  getPriceInfo$,
  getProcess$,
  PaymentCrefopayConfig,
  PaymentInfo, PaymentInfoRequestType,
  PaymentInfoStatus,
  PriceInfo,
  ProcessState,
  ValidationError
} from 'fe-starter-shared';
import {Store} from '@ngrx/store';
import {getCartState$, isRequestPending$} from '../../../../cart/store';
import {PaymentCrefopayHostedService} from '../../../service/payment-crefopay-hosted.service';
import {DOCUMENT} from '@angular/common';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import * as sharedSocketsActions from 'fe-starter-shared';

declare var SecureFieldsClient: any;

@Component({
  templateUrl: './secure-fields.component.html'
})
export class CrefopaySecureFieldsComponent extends PaymentFormComponent implements OnInit, OnDestroy {
  data: PaymentFormData;
  validationErrors: ValidationError[] = [];
  onValid: EventEmitter<PaymentFormResult> = new EventEmitter<PaymentFormResult>();

  _result: PaymentInfo = new PaymentInfo();
  _subscription: Subscription;
  _jsLoad: Subscription;

  paymentForm: FormGroup;
  public useStoredPayment: boolean;
  public isRequestPending: boolean;
  public priceInfo: PriceInfo;
  public skipSaleFee: boolean;
  public externalPaymentWaitingTime: number;
  public showErrorRequiredFields = false;
  public showErrorNotValid = false;

  private secureFieldsClientInstance: any;
  public paymentCrefopayConfig: PaymentCrefopayConfig;

  constructor(private _store: Store<any>,
              private _cd: ChangeDetectorRef,
              private _formBuilder: FormBuilder,
              private readonly svc: PaymentCrefopayHostedService,
              @Inject(DOCUMENT) private readonly document: any,
              private ngZone: NgZone) {
    super();
    this._subscription = new Subscription();
    this._subscription.add(
      this._store.pipe(isRequestPending$)
        .subscribe(isRequestPending => this.isRequestPending = isRequestPending)
    ).add(
      this._store.pipe(getCartState$)
        .subscribe(cartState => {
          this.skipSaleFee = cartState.skipSaleFee;
        })
    ).add(
      this._store.pipe(getPriceInfo$)
        .subscribe((priceInfo: PriceInfo) => this.priceInfo = priceInfo)
    ).add(
      this._store.pipe(getPaymentCrefopayConfig$)
        .subscribe((paymentCrefopayConfig: PaymentCrefopayConfig) => this.paymentCrefopayConfig = paymentCrefopayConfig)
    ).add(
      this._store.pipe(getProcess$)
        .subscribe((processState: ProcessState) => {
          if (processState.externalPaymentWaitingTime) {
            this.externalPaymentWaitingTime = moment.duration(processState.externalPaymentWaitingTime).asMinutes();
          } else {
            this.externalPaymentWaitingTime = null;
          }
        })
    );
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    // tslint:disable-next-line:no-string-literal
    window['crefopayPaymentRegisteredCallback'] = null;
    // tslint:disable-next-line:no-string-literal
    window['crefopayInitializationCompleteCallback'] = null;

    if (this._jsLoad != null) {
      this._jsLoad.unsubscribe();
    }

    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  openRedirectUrl(): void {
    this._store.dispatch(
      new sharedSocketsActions.SocketsCloseConnection()
    );
    setTimeout(() => window.location.href = this.data.paymentInfo.providerTypeRedirectUrl, 1250);
  }

  hasRedirectPayment(): boolean {
    // tslint:disable-next-line:max-line-length
    return this.data.paymentInfo != null && (this.data.paymentInfo.status === PaymentInfoStatus.REDIRECT_NEEDED || this.data.paymentInfo.status === PaymentInfoStatus.REDIRECT_WAITING_FOR_PROVIDER_STATUS);
  }

  hasStoredPayment(): boolean {
    // tslint:disable-next-line:max-line-length
    return this.data.paymentInfo != null && (this.data.paymentInfo.status === PaymentInfoStatus.REDIRECT_NEEDED || this.data.paymentInfo.status === PaymentInfoStatus.VALID);
  }

  private initPaymentMethod(reset: boolean = false) {
    if (!reset && this.hasStoredPayment()) {
      this.useStoredPayment = true;
      this.onValid.emit({
        useStoredPayment: this.useStoredPayment,
        bookDirectly: false,
        payment: null
      });
    } else {
      this.useStoredPayment = false;
      this._result = {
        ...new PaymentInfo(),
        provider: this.data.paymentInfo.provider,
        providerType: this.data.paymentInfo.providerType,
      };
      this.onValid.emit({
        useStoredPayment: this.useStoredPayment,
        bookDirectly: false,
        payment: {
          ...this._result
        }
      });
      this._jsLoad = this.svc.lazyLoad().subscribe(_ => {
        if (!SecureFieldsClient) {
          SecureFieldsClient = this.document.defaultView.SecureFieldsClient;
        }
        this.setupCrefopay();
      });
    }
  }

  private initForms(): void {
    this.paymentForm = this._formBuilder
      .group({
        providerTypeIdMasked: [
          this._result.providerTypeIdMasked,
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ],
        providerTypeId: [
          this._result.providerTypeId,
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ]
      });
    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  submitForm(): void {
    this.secureFieldsClientInstance.registerPayment();
    // this.isRequestPending = true;
    // if (this.secureFieldsClientInstance.isComplete()) {
    //   this.showErrorRequiredFields = false;
    //   this.secureFieldsClientInstance.creditCardCheck('crefopayCreditCardHostedCallback');
    // } else {
    //   this.showErrorRequiredFields = true;
    // }
  }

  checkInitializationCompleteCallbackPrivate(response) {
    console.log('>>> InitializationCompleteCallback >>>', response);
    if (response.resultCode === 0) {
      this.showErrorNotValid = false;
    } else {
      this.showErrorNotValid = true;
    }
    this.isRequestPending = false;
    this._cd.detectChanges();
  }

  checkPaymentRegisteredCallbackPrivate(response) {
    this.isRequestPending = false;
    console.log('>>> PaymentRegisteredCallback >>>', response);
    // {resultCode: 0, salt: '4a8c72363340ce2c', paymentInstrumentId: 'vJKkWk2P8B3OgddTiA3A7w', orderNo: 'be7904bd-c02d-42f2-a50f-f51512601111', paymentMethod: 'CC3D'}
    // {resultCode: 0, salt: '8f7698f798c8c0b4', orderNo: '9cc8eb27-65cb-488f-8b80-2c4dc5a101c7', paymentMethod: 'PAYPAL'}
    if (response.resultCode === 0) {
      this.showErrorNotValid = false;
      if (this.data.paymentInfo.providerType === 'CREDIT_CARD') {
        this.paymentForm.patchValue({
          providerTypeId: response.paymentInstrumentId,
          providerTypeIdMasked: '**** **** **** **** ****'
        });
        this.onValid.emit({
          useStoredPayment: this.useStoredPayment,
          bookDirectly: false,
          payment: {
            ...this._result,
            providerTypeId: this.paymentForm.value.providerTypeId,
            providerTypeIdMasked: this.paymentForm.value.providerTypeIdMasked
          }
        });
      } else {
        this.onValid.emit({
          useStoredPayment: this.useStoredPayment,
          bookDirectly: false,
          payment: {
            ...this._result
          }
        });
      }
    } else {
      this.showErrorNotValid = true;
    }

    try {
      this._cd.detectChanges();
    } catch (e) {
      //
    }
  }

  public clearStoredPayment() {
    this.initPaymentMethod(true);
  }

  setData(data: PaymentFormData): void {
    // tslint:disable-next-line:no-string-literal
    window['crefopayPaymentRegisteredCallback'] = this.checkPaymentRegisteredCallbackPublic.bind(this);
    // tslint:disable-next-line:no-string-literal
    window['crefopayInitializationCompleteCallback'] = this.checkInitializationCompleteCallbackPublic.bind(this);

    // tslint:disable-next-line:max-line-length
    if (data.paymentInfo.status === PaymentInfoStatus.INCOMPLETE && data.paymentInfo.providerTypeReference != null && (this.data == null || data.paymentInfo.providerTypeReference !== this.data.paymentInfo.providerTypeReference)) {
      this.data = data;
      this.initForms();
      this.initPaymentMethod();
    } else {
      this.data = data;
      if (this.data.paymentInfo.providerType === 'PAYPAL' && this.data.paymentInfo.status === PaymentInfoStatus.REDIRECT_NEEDED) {
        this.openRedirectUrl();
      }
    }
    try {
      this._cd.detectChanges();
    } catch (e) {
      //
    }
  }

  setValidationErrors(validationErrors: ValidationError[]): void {
    this.validationErrors = validationErrors;

    if (this.validationErrors != null) {
      this.validationErrors.forEach(validationError => {
        if (this.paymentForm.controls[validationError.field] != null) {
          this.paymentForm.controls[validationError.field].setErrors({remote: {invalid: validationError.message}});
        }
      });
    }
  }

  setupCrefopay() {
    console.log('>>> setupCrefopay shopKeyPublic >>>', this.paymentCrefopayConfig.shopKeyPublic);
    console.log('>>> setupCrefopay orderID >>>', this.data.paymentInfo.providerTypeReference);
    console.log('>>> setupCrefopay configuration >>>', this.paymentCrefopayConfig.secureFields);
    this.secureFieldsClientInstance = new SecureFieldsClient(
      this.paymentCrefopayConfig.shopKeyPublic,
      this.data.paymentInfo.providerTypeReference,
      window['crefopayPaymentRegisteredCallback'],
      window['crefopayInitializationCompleteCallback'],
      this.paymentCrefopayConfig.secureFields
    );
  }

  checkPaymentRegisteredCallbackPublic(response) {
    this.ngZone.run(() => this.checkPaymentRegisteredCallbackPrivate(response));
  }

  checkInitializationCompleteCallbackPublic(response) {
    this.ngZone.run(() => this.checkInitializationCompleteCallbackPrivate(response));
  }
}
