import {ChangeDetectorRef, Component, EventEmitter, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {PaymentFormComponent, PaymentFormData} from '../payment-form.component';
import {PaymentFormResult} from '../../../models/payment-form-result.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationError} from 'fe-starter-shared';
import {Store} from '@ngrx/store';
import {getCartState$, isRequestPending$} from '../../../../cart/store';
import {getPriceInfo$, getProcess$} from 'fe-starter-shared';
import {PriceInfo} from 'fe-starter-shared';
import {PaymentPayoneHostedService} from '../../../service/payment-payone-hosted.service';
import {DOCUMENT} from '@angular/common';
import {getPaymentPayoneConfig$} from 'fe-starter-shared';
import {PaymentPayoneConfig} from 'fe-starter-shared';
import {PaymentProviderTypes} from 'fe-starter-shared';
import {PaymentInfo} from 'fe-starter-shared';
import {PaymentInfoStatus} from 'fe-starter-shared';
import {ProcessState} from 'fe-starter-shared';
import * as moment from 'moment';
import {Subscription} from 'rxjs';

declare var Payone: any;

@Component({
  templateUrl: './credit-card.component.html'
})
export class PayoneCreditCardComponent extends PaymentFormComponent implements OnInit, OnDestroy {
  data: PaymentFormData;
  validationErrors: ValidationError[] = [];
  onValid: EventEmitter<PaymentFormResult> = new EventEmitter<PaymentFormResult>();

  _result: PaymentInfo = new PaymentInfo();
  _subscription: Subscription;

  paymentForm: FormGroup;
  public useStoredPayment: boolean;
  public isRequestPending: boolean;
  public priceInfo: PriceInfo;
  public skipSaleFee: boolean;
  public externalPaymentWaitingTime: number;
  public showErrorRequiredFields = false;
  public showErrorNotValid = false;

  private iFrames: any;
  public paymentPayoneConfig: PaymentPayoneConfig;

  constructor(private _store: Store<any>,
              private _cd: ChangeDetectorRef,
              private _formBuilder: FormBuilder,
              private readonly svc: PaymentPayoneHostedService,
              @Inject(DOCUMENT) private readonly document: any,
              private ngZone: NgZone) {
    super();
    this._subscription = new Subscription();
    this._subscription.add(
      this._store.pipe(isRequestPending$)
        .subscribe(isRequestPending => this.isRequestPending = isRequestPending)
    ).add(
      this._store.pipe(getCartState$)
        .subscribe(cartState => {
          this.skipSaleFee = cartState.skipSaleFee;
        })
    ).add(
      this._store.pipe(getPriceInfo$)
        .subscribe((priceInfo: PriceInfo) => this.priceInfo = priceInfo)
    ).add(
      this._store.pipe(getPaymentPayoneConfig$)
        .subscribe((paymentPayoneConfig: PaymentPayoneConfig) => this.paymentPayoneConfig = paymentPayoneConfig)
    ).add(
      this._store.pipe(getProcess$)
        .subscribe((processState: ProcessState) => {
          if (processState.externalPaymentWaitingTime) {
            this.externalPaymentWaitingTime = moment.duration(processState.externalPaymentWaitingTime).asMinutes();
          } else {
            this.externalPaymentWaitingTime = null;
          }
        })
    );
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-string-literal
    window['payoneCreditCardHostedCallback'] = this.checkCallbackPublic.bind(this);
    this.initForms();
    this.initPaymentMethod();
  }

  ngOnDestroy() {
    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  private hasRedirectPayment(): boolean {
    return this.data.paymentInfo != null && this.data.paymentInfo.status === PaymentInfoStatus.REDIRECT_NEEDED;
  }

  private hasStoredPayment(): boolean {
    return this.data.paymentInfo != null && this.data.paymentInfo.status === PaymentInfoStatus.VALID;
  }

  private initPaymentMethod(reset: boolean = false) {
    if (!reset && this.hasStoredPayment()) {
      this.useStoredPayment = true;
      this.onValid.emit({
        useStoredPayment: this.useStoredPayment,
        bookDirectly: false,
        payment: null
      });
    } else {
      this.useStoredPayment = false;
      this._result = {
        ...new PaymentInfo(),
        provider: this.data.paymentInfo.provider,
        providerType: this.data.paymentInfo.providerType,
      };
      this.paymentForm.patchValue(this._result);

      this._cd.detectChanges();
      this.svc.lazyLoad().subscribe(_ => {
        if (!Payone) {
          Payone = this.document.defaultView.Payone;
        }

        this.setupPayone();
      });
    }
  }

  private initForms(): void {
    this.paymentForm = this._formBuilder
      .group({
        providerTypeIdMasked: [
          this._result.providerTypeIdMasked,
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ],
        providerTypeId: [
          this._result.providerTypeId,
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ]
      });

    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  submitForm(): void {
    if (this.iFrames.isComplete()) {
      this.showErrorRequiredFields = false;
      this.iFrames.creditCardCheck('payoneCreditCardHostedCallback');
    } else {
      this.showErrorRequiredFields = true;
    }
  }

  checkCallbackPrivate(response) {
    if (response.status === 'VALID') {
      this.showErrorNotValid = false;
      this.paymentForm.patchValue({
        providerTypeId: response.pseudocardpan,
        providerTypeIdMasked: response.truncatedcardpan
      });
      /**
       * cardexpiredate: "2403"
       * cardtype: "V"
       * errorcode: null
       * errormessage: null
       * pseudocardpan: "9410010000207776972"
       * status: "VALID"
       * truncatedcardpan: "411111XXXXXX1111"
       *
       * processId: string;
       * provider: string;
       * providerType: string;
       * providerTypeId: string;
       * providerTypeIdEncrypted: string;
       * providerTypeIdMasked: string;
       * providerTypeCustomerName: string;
       */
      this.onValid.emit({
        useStoredPayment: this.useStoredPayment,
        bookDirectly: false,
        payment: {
          ...this._result,
          providerTypeId: this.paymentForm.value.providerTypeId,
          providerTypeIdMasked: this.paymentForm.value.providerTypeIdMasked
        }
      });
    } else {
      this.showErrorNotValid = true;
    }
  }

  public clearStoredPayment() {
    this.initPaymentMethod(true);
  }

  setData(data: PaymentFormData): void {
    this.data = data;
    try {
      this._cd.detectChanges();
    } catch (e) {
      //
    }
  }

  setValidationErrors(validationErrors: ValidationError[]): void {
    this.validationErrors = validationErrors;

    if (this.validationErrors != null) {
      this.validationErrors.forEach(validationError => {
        if (this.paymentForm.controls[validationError.field] != null) {
          this.paymentForm.controls[validationError.field].setErrors({remote: {invalid: validationError.message}});
        }
      });
    }
  }

  setupPayone() {

    const supportedCardtypes: Array<string> = [];
    switch (this.data.selectedPaymentMethod.method) {
      case PaymentProviderTypes.CREDIT_CARD_VISA:
        supportedCardtypes.push('V');
        break;
      case PaymentProviderTypes.CREDIT_CARD_MASTER_CARD:
        supportedCardtypes.push('M');
        break;
      case PaymentProviderTypes.CREDIT_CARD_AMERICAN_EXPRESS:
        supportedCardtypes.push('A');
        break;
      case PaymentProviderTypes.CREDIT_CARD_DINERS_DISCOVER:
        supportedCardtypes.push('D');
        break;
      case PaymentProviderTypes.CREDIT_CARD_JCB:
        supportedCardtypes.push('J');
        break;
      case PaymentProviderTypes.CREDIT_CARD_MAESTRO_INTERNATIONAL:
        supportedCardtypes.push('O');
        break;
      case PaymentProviderTypes.CREDIT_CARD_CHINA_UNION_PAY:
        supportedCardtypes.push('P');
        break;
      case PaymentProviderTypes.CREDIT_CARD_UATP_AIRPLUS:
        supportedCardtypes.push('U');
        break;
    }

    this.iFrames = new Payone.ClientApi.HostedIFrames({
      autoCardtypeDetection: {
        supportedCardtypes
      },
      fields: {
        // cardtype: {
        //   selector: "cardtype",
        //   cardtypes: ["V", "M", "A"]
        // },
        cardpan: {
          selector: 'cardpan',
          type: 'text',
          // style: "font-size: 1em; border: 1px solid #000; width: 175px;"
        },
        cardcvc2: {
          selector: 'cardcvc2',
          type: 'password',
          // style: "font-size: 1em; border: 1px solid #000; width: 175px;",
          size: '4',
          maxlength: '4',
          length: {A: 4, V: 3, M: 3, J: 0}
        },
        cardexpiremonth: {
          selector: 'cardexpiremonth', type: 'select',
          size: '2',
          maxlength: '2',
          iframe: {
            width: '50px'
          }
        },
        cardexpireyear: {
          selector: 'cardexpireyear', type: 'select',
          iframe: {
            width: '80px'
          }
        }
      },
      defaultStyle: {
        input: 'font-size: 1em; border: 1px solid #495057;',
        select: 'font-size: 1em; border: 1px solid #495057;',
        iframe: {
          height: '33px',
          width: '220px'
        }
      },
      error: 'errorOutput',
      language: Payone.ClientApi.Language.de
    }, this.paymentPayoneConfig);
  }

  checkCallbackPublic(response) {
    this.ngZone.run(() => this.checkCallbackPrivate(response));
  }
}
