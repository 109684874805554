import {localeBaseDe} from '../de';
import {mergeDeep} from 'fe-starter-shared';

const localeClientDe = {
  data: {
    LOGIN: {},
    PERSONAL_DETAILS: {}
  }
};

export const german = mergeDeep({}, localeClientDe, localeBaseDe);
