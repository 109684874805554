import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class TitleService {

  private _pages = {
    checkout: {
      prefix: '/checkout/',
      name: 'PAGE_TITLES.CHECKOUT.TITLE',
      children: {
        init: {
          suffix: '/init',
          name: 'PAGE_TITLES.CHECKOUT.INIT'
        },
        info: {
          suffix: '/info',
          name: 'PAGE_TITLES.CHECKOUT.INFO'
        },
        seating: {
          suffix: '/seating',
          name: 'PAGE_TITLES.CHECKOUT.SEATING'
        },
        details: {
          suffix: '/details',
          name: 'PAGE_TITLES.CHECKOUT.DETAILS'
        },
        payment: {
          suffix: '/payment',
          name: 'PAGE_TITLES.CHECKOUT.PAYMENT'
        }
      }
    },
    confirmation: {
      prefix: '/confirmation/',
      name: 'PAGE_TITLES.CONFIRMATION.TITLE'
    },
    checkEmailAddress: {
      prefix: '/check-email-address/',
      name: 'PAGE_TITLES.CHANGE_EMAIL_ADDRESS.TITLE'
    },
    verifyAccount: {
      prefix: '/verify_account/',
      name: 'PAGE_TITLES.VERIFY_ACCOUNT.TITLE'
    },
    passwordReset: {
      prefix: '/password-reset/',
      name: 'PAGE_TITLES.PASSWORD_RESET.TITLE'
    },
    error: {
      prefix: '/error',
      name: 'PAGE_TITLES.ERROR.TITLE'
    },
    pageNotFound: {
      prefix: 'not-found',
      name: 'PAGE_TITLES.PAGENOTFOUND.TITLE'
    },
  };

  private readonly _pageBaseTitle: string = 'PAGE_TITLES.BASE';

  constructor(private _translationService: TranslateService) {}

  getTitle(url: string): string {
    let result = this._translationService.instant(this._pageBaseTitle);

    Object.keys(this._pages).forEach(key => {
      const page = this._pages[key];
      if (page != null && url.startsWith(page.prefix)) {
        result += ' - ' + this.getName(page, url);
      }
    });

    return result;
  }

  private getName(item, url) {
    let name = '';

    if (item.hasOwnProperty('name')) {
      name += this._translationService.instant(item.name);
    }
    if (item.hasOwnProperty('children')) {
      name += this.getNamesOfChildren(item.children, url);
    }
    return name;
  }

  private getNamesOfChildren(children, url) {
    let name = '';
    Object.keys(children).forEach(key => {
      const child = children[key];
      if (child.hasOwnProperty('suffix')) {
        if (url.endsWith(child.suffix)) {
          name += ' - ' + this.getName(child, url);
        }
      }
    });
    return name;
  }
}
