import {map, withLatestFrom} from 'rxjs/operators';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as processPaymentInfoActions from '../../store/payment-info/payment-info.actions';
import {PaymentInfoActions} from './payment-info.actions';
import * as socketsActions from '../../store/sockets/sockets.actions';
import {PaymentInfoSocketService} from '../../services/sockets/payment-info.service';
// noinspection TypeScriptPreferShortImport
import {getProcess$} from '../process/process.selectors';

export class PaymentInfoEffects {

  /**
   * Connect to sockets
   */
  @Effect({dispatch: false})
  onConnectToSockets$ = this.actions$.pipe(
    ofType<socketsActions.SocketsConnectToSockets>(socketsActions.SocketsActions.CONNECT_TO_SOCKETS),
    map(action => action.payload),
    withLatestFrom(this.store.pipe((getProcess$))),
    map(([, process]) => this.paymentInfoSocketService.subscribe(process.id))
  );

  /**
   * On init received
   */
  @Effect({dispatch: false})
  onInitReceived$ = this.actions$.pipe(
    ofType<processPaymentInfoActions.PaymentInfoInitReceived>(PaymentInfoActions.PAYMENT_INFO_INIT_RECEIVED),
    map(action => action.payload)
  );

  /**
   * On update received
   */
  @Effect({dispatch: false})
  onUpdateReceived$ = this.actions$.pipe(
    ofType<processPaymentInfoActions.PaymentInfoUpdateReceived>(PaymentInfoActions.PAYMENT_INFO_UPDATE_RECEIVED),
    map(action => action.payload)
  );

  /**
   * Constructor
   */
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private paymentInfoSocketService: PaymentInfoSocketService
  ) {
  }
}
