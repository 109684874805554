import {localeBaseEn} from '../en';
import {mergeDeep} from 'fe-starter-shared';

const localeClientEn = {
  data: {
    LOGIN: {},
    PERSONAL_DETAILS: {}
  }
};

export const english = mergeDeep({}, localeClientEn, localeBaseEn);
