import {EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {PaymentInfo, PaymentMethod, User} from 'fe-starter-shared';
import {PaymentFormResult} from '../../models/payment-form-result.model';

export class PaymentFormData {
  public args: any = {};

  public customer: User = null;
  public paymentInfo: PaymentInfo = null;
  public selectedPaymentMethod: PaymentMethod = null;

  constructor(custom?: any) {
    if (custom != null) {
      Object.keys(custom).forEach((key) => {
        if (this.hasOwnProperty(key) && this[key] == null) {
          this[key] = custom[key];
        } else {
          this.args[key] = custom[key];
        }
      });
    }
  }
}

export abstract class PaymentFormComponent implements OnInit, OnDestroy {
  onValid: EventEmitter<PaymentFormResult>;

  abstract setData(data: PaymentFormData): void;

  abstract setValidationErrors(validationErrors): void;

  abstract submitForm(): void;

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }
}
