import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {PaymentFormComponent, PaymentFormData} from '../payment-form.component';
import {PaymentFormResult} from '../../../models/payment-form-result.model';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {getPriceInfo$, PaymentInfo, PriceInfo, ValidationError} from 'fe-starter-shared';
import {Store} from '@ngrx/store';
import {getCartState$, isRequestPending$} from '../../../../cart/store';

@Component({
  templateUrl: './cash.component.html'
})
export class DefaultCashComponent extends PaymentFormComponent implements OnInit, OnDestroy {
  data: PaymentFormData;
  validationErrors: ValidationError[] = [];
  onValid: EventEmitter<PaymentFormResult> = new EventEmitter<PaymentFormResult>();

  _result: PaymentInfo = new PaymentInfo();
  _subscription: Subscription;

  paymentForm: FormGroup;
  public useStoredPayment: boolean;
  public isRequestPending: boolean;
  public priceInfo: PriceInfo;
  public skipSaleFee: boolean;

  constructor(private _store: Store<any>,
              private _cd: ChangeDetectorRef,
              private _formBuilder: FormBuilder) {
    super();
    this._subscription = new Subscription();
    this._subscription.add(
      this._store
        .pipe(isRequestPending$)
        .subscribe(isRequestPending => this.isRequestPending = isRequestPending)
    ).add(
      this._store.pipe(getCartState$)
        .subscribe(cartState => this.skipSaleFee = cartState.skipSaleFee)
    ).add(
      this._store.pipe(getPriceInfo$)
        .subscribe((priceInfo: PriceInfo) => this.priceInfo = priceInfo)
    );
  }

  ngOnInit(): void {
    this._result = {
      ...new PaymentInfo(),
      ...this.data.paymentInfo,
    };
    this.onValid.emit({
      useStoredPayment: true,
      bookDirectly: false,
      payment: this._result
    });
  }

  ngOnDestroy() {
    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  setData(data: PaymentFormData): void {
    this.data = data;
    try {
      this._cd.detectChanges();
    } catch (e) {
      //
    }
  }

  setValidationErrors(validationErrors: ValidationError[]): void {
    return null;
  }

  submitForm(): void {
  }
}
