import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {PaymentFormComponent, PaymentFormData} from '../payment-form.component';
import {PaymentFormResult} from '../../../models/payment-form-result.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {getPriceInfo$, getProcess$, PaymentInfo, PaymentInfoStatus, PriceInfo, ProcessState, ValidationError} from 'fe-starter-shared';
import {Store} from '@ngrx/store';
import {getCartState$, isRequestPending$} from '../../../../cart/store';
import * as moment from 'moment';
import {Subscription} from 'rxjs';

declare var Payone: any;

@Component({
  templateUrl: './external-directly.component.html'
})
export class PayoneExternalDirectlyComponent extends PaymentFormComponent implements OnInit, OnDestroy {
  data: PaymentFormData;
  validationErrors: ValidationError[] = [];
  onValid: EventEmitter<PaymentFormResult> = new EventEmitter<PaymentFormResult>();

  _result: PaymentInfo = new PaymentInfo();
  _subscription: Subscription;

  paymentForm: FormGroup;
  public useStoredPayment: boolean;
  public isRequestPending: boolean;
  public bookDirectly = true;
  public priceInfo: PriceInfo;
  public pay: PriceInfo;
  public skipSaleFee: boolean;
  public externalPaymentWaitingTime: number;

  constructor(private _store: Store<any>,
              private _cd: ChangeDetectorRef,
              private _formBuilder: FormBuilder) {
    super();
    this._subscription = new Subscription();
    this._subscription.add(
      this._store.pipe(isRequestPending$)
        .subscribe(isRequestPending => this.isRequestPending = isRequestPending)
    ).add(
      this._store.pipe(getCartState$)
        .subscribe(cartState => this.skipSaleFee = cartState.skipSaleFee)
    ).add(
      this._store.pipe(getPriceInfo$)
        .subscribe((priceInfo: PriceInfo) => this.priceInfo = priceInfo)
    ).add(
      this._store.pipe(getProcess$)
        .subscribe((processState: ProcessState) => {
          if (processState.externalPaymentWaitingTime) {
            this.externalPaymentWaitingTime = moment.duration(processState.externalPaymentWaitingTime).asMinutes();
          } else {
            this.externalPaymentWaitingTime = null;
          }
        })
    );
  }

  ngOnInit(): void {
    this.initForms();
    this.initPaymentMethod();

    if (this.data.paymentInfo.status === PaymentInfoStatus.VALID) {
      this.onValid.emit({
        useStoredPayment: true,
        bookDirectly: true,
        payment: {
          ...new PaymentInfo(),
          provider: this.data.paymentInfo.provider,
          providerType: this.data.paymentInfo.providerType,
        }
      });
    }
  }

  // ngOnInit(): void {
  //   this._result = {
  //     ...new PaymentInfo(),
  //     ...this.data.paymentInfo,
  //   };
  //   this.onValid.emit({
  //     useStoredPayment: true,
  //     payment: this._result
  //   });
  // }

  ngOnDestroy() {
    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  private initPaymentMethod() {
    this.useStoredPayment = true;
    this.onValid.emit({
      useStoredPayment: this.useStoredPayment,
      bookDirectly: false,
      payment: null
    });
  }

  private initForms(): void {
    this.paymentForm = this._formBuilder
      .group({});

    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  submitForm(): void {
    if (this.paymentForm.valid) {
      this.onValid.emit({
        useStoredPayment: false,
        bookDirectly: false,
        payment: {
          ...new PaymentInfo(),
          provider: this.data.paymentInfo.provider,
          providerType: this.data.paymentInfo.providerType,
        }
      });
    } else {
      this.onValid.emit(null);
    }
  }

  setData(data: PaymentFormData): void {
    this.data = data;
    try {
      this._cd.detectChanges();
    } catch (e) {
      //
    }
  }

  setValidationErrors(validationErrors: ValidationError[]): void {
    return null;
  }
}
