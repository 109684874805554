import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import {ErrorCodes, ErrorUserActions} from 'fe-starter-shared';
import * as errorActions from 'fe-starter-shared';
import {Store} from '@ngrx/store';
import {Error} from 'fe-starter-shared';

@Component({
  selector: 'c360-error',
  templateUrl: './error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ErrorComponent implements OnChanges {

  @HostBinding('class.container') someField = true;
  @Input() error: Error;
  @Output() exitCode: EventEmitter<string> = new EventEmitter<string>();

  public errorActions = ErrorUserActions;
  public errorCodes = ErrorCodes;
  public isConnectionError = false;

  private _successCodes: string[] = [
    ErrorCodes.VERIFICATION_FAILED,
    'PS-10'
  ];
  private _warningCodes: string[] = [
    ErrorCodes.PROCESS_EXISTS
  ];
  private _dangerCodes: string[] = [];
  private _primaryCodes: string[] = [];
  private _secondaryCodes: string[] = [];
  private _tertiaryCodes: string[] = [];
  private _quaternaryCodes: string[] = [];

  constructor(private _store: Store<any>,
              private _cd: ChangeDetectorRef) {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.error.currentValue != null) {
      this.error = changes.error.currentValue;
      this.isConnectionError = (this.error != null && this.error.errorCode === ErrorCodes.CONNECTION_LOST);
      if (
        (
          this.isConnectionError ||
          (this.error.errorCode !== ErrorCodes.PROCESS_EXISTS &&
            this.error.errorAction === ErrorUserActions.RETRY_LAST)
        ) &&
        this.error.attempt <= this.error.maxReattempts &&
        this.error.attemptAction != null
      ) {
        setTimeout(() => {
          this._store.dispatch(
            this.error.attemptAction
          );
          if (!this.isConnectionError) {
            this._store.dispatch(
              new errorActions.DismissError(this.error)
            );
          }
        }, this.error.attemptInterval);
      }
    }
    this._cd.detectChanges();
  }

  closeAlert(action) {
    this.exitCode.emit(action);
  }

  getErrorType(): string {
    if (this.error != null && this.error.errorCode != null) {

      if (this._successCodes.indexOf(this.error.errorCode) > -1) {
        return 'success';
      }
      if (this._warningCodes.indexOf(this.error.errorCode) > -1) {
        return 'warning';
      }
      if (this._dangerCodes.indexOf(this.error.errorCode) > -1) {
        return 'danger';
      }
      if (this._primaryCodes.indexOf(this.error.errorCode) > -1) {
        return 'primary';
      }
      if (this._secondaryCodes.indexOf(this.error.errorCode) > -1) {
        return 'secondary';
      }
      if (this._tertiaryCodes.indexOf(this.error.errorCode) > -1) {
        return 'tertiary';
      }
      if (this._quaternaryCodes.indexOf(this.error.errorCode) > -1) {
        return 'quaternary';
      }
    }
    return 'primary';
  }
}
