<ng-container *ngIf="data.paymentInfo.status === 'INCOMPLETE'">
  <c360-loading-bar message="{{'PAYMENT.FORMS.' + data.selectedPaymentMethod.method + '.LOADING' | translate}}"></c360-loading-bar>
</ng-container>

<ng-container *ngIf="data.paymentInfo.status !== 'INCOMPLETE' && priceInfo">
  <div *ngIf="hasRedirectPayment()">
    <div *ngIf="data.paymentInfo.status === 'REDIRECT_NEEDED'">
      <button class="btn btn-block btn-primary btn-lg py-3" (click)="openRedirectUrl()" [translate]="'PAYMENT.STORED.' + data.paymentInfo.providerType +'.REDIRECT'"></button>
    </div>
    <c360-loading-bar *ngIf="data.paymentInfo.status === 'REDIRECT_WAITING_FOR_PROVIDER_STATUS'" message="{{'PAYMENT.FORMS.' + data.selectedPaymentMethod.method + '.' + data.paymentInfo.status | translate}}"></c360-loading-bar>
  </div>
  <div *ngIf="hasStoredPayment()" role="alert" class="alert alert-success text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.' + data.selectedPaymentMethod.method + '.' + data.paymentInfo.status"></div>

  <form role="form"
        *ngIf="!hasRedirectPayment() && !hasStoredPayment()"
        (ngSubmit)="submitForm()"
        [formGroup]="paymentForm">

    <div *ngIf="externalPaymentWaitingTime" role="alert" class="alert alert-success text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.' + data.selectedPaymentMethod.method + '.WAITING_TIME'" [translateParams]="{time: externalPaymentWaitingTime}"></div>
    <div *ngIf="data.paymentInfo.status === 'REDIRECT_ABORTED'" role="alert" class="alert alert-warning text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.' + data.selectedPaymentMethod.method + '.' + data.paymentInfo.status"></div>
    <div *ngIf="data.paymentInfo.status === 'REDIRECT_FAILED'" role="alert" class="alert alert-danger text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.' + data.selectedPaymentMethod.method + '.' + data.paymentInfo.status"></div>

    <button type="submit" class="btn btn-block btn-primary btn-lg py-3" [translate]="'PAYMENT.FORMS.' + data.paymentInfo.providerType +'.SUBMIT'"></button>

  </form>
</ng-container>
