import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';

import {Observable, of} from 'rxjs';

@Injectable()
export class CoreGuard implements CanActivate {
  constructor() {}

  canActivate(): Observable<boolean> {
    // At this stage no verification is required
    return of(true);
  }
}
