import {Action} from '@ngrx/store';
import {PaymentInfo} from '../../models/payment-info.model';

export class PaymentInfoActions {
  static PAYMENT_INFO_INIT_RECEIVED = '[payment-info] Init Received';
  static PAYMENT_INFO_UPDATE_RECEIVED = '[payment-info] Update Received';
}

export class PaymentInfoInitReceived implements Action {
  readonly type = PaymentInfoActions.PAYMENT_INFO_INIT_RECEIVED;

  constructor(public payload: PaymentInfo) {
  }
}

export class PaymentInfoUpdateReceived implements Action {
  readonly type = PaymentInfoActions.PAYMENT_INFO_UPDATE_RECEIVED;

  constructor(public payload: PaymentInfo) {
  }
}
