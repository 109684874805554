import {localeBaseDe} from '../de';
import {mergeDeep} from 'fe-starter-shared';

const localeClientDe = {
  data: {
    CART : {
    },
  }
};

export const german = mergeDeep({}, localeClientDe, localeBaseDe);
