import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {getTokens$} from 'fe-starter-shared';
import {Store} from '@ngrx/store';
import {v4 as uuid} from 'uuid';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private headersAuth: { [key: string]: any };

  constructor(private store: Store<any>) {
    this.store.pipe((getTokens$)).subscribe(
      tokens => {
        if (tokens != null && tokens.accessToken != null) {
          this.headersAuth = {
            Authorization: 'Bearer ' + tokens.accessToken
          };
        } else {
          this.headersAuth = {};
        }
      }
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.headersAuth != null) {
      if (request.url.indexOf(environment.urls.auth) === -1) {
        request = request.clone({
          setHeaders: {
            ...this.headersAuth,
            'client-type': environment.clientType,
            'client-version': environment.version,
            requestId: uuid()
          }
        });
      }
    }

    return next.handle(request);
  }
}
