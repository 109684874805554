export const localeBaseDe = {
  lang: 'de',
  data: {
    TICKETING: {
      LOADING: 'Lade Ticket Daten',
      TITLE: 'Bitte wählen Sie die Anzahl Ihrer gewünschten Tickets:',
      TITLE_DISABLED: 'Sie haben die folgenden Tickets gewählt:',
      HEADER: 'Ticketauswahl',
      SUBTITLE_PLURAL_ZERO: 'Sie haben noch keine Plätze gewählt.',
      SUBTITLE_PLURAL_ONE: 'Sie haben {{count}} Platz gewählt. Das passende Ticket finden Sie hier:',
      SUBTITLE_PLURAL_OTHER: 'Sie haben {{count}} Plätze gewählt. Die passenden Tickets finden Sie hier:',
      FOOTER: 'Zusätzliche Informationen zu den Tickets',
      TICKETS_FROM: 'ab',
      TICKETS_AVAILABLE: 'übrig',
      SUBTITLE_PLURAL: {
        '=0': 'TICKETING.SUBTITLE_PLURAL_ZERO',
        '=1': 'TICKETING.SUBTITLE_PLURAL_ONE',
        other: 'TICKETING.SUBTITLE_PLURAL_OTHER'
      },
      GO_TO_SEAT_SELECTION: 'Jetzt Plätze wählen',
      CHANGE: 'Tickets ändern',
      DECREASE: {
        TITLE: 'Achtung',
        MESSAGE: 'Durch die Abwahl eines Tickets wird Ihre aktuelle Sitzauswahl zurückgesetzt.',
        YES: 'OK',
        NO: 'Zurück'
      }
    }
  }
};
