import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';

import * as fromLayoutActions from '../../../core/store/layout';
import {AuthenticationService} from 'fe-starter-shared';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'c360-page-not-found',
  templateUrl: './page-not-found.html',
  styleUrls: [
    './page-not-found.css'
  ]
})
export class PageNotFoundComponent implements OnInit {

  constructor(private store: Store<fromLayoutActions.LayoutState>,
              private _authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.store.dispatch(
      new fromLayoutActions.HideSplashscreen()
    );
  }

  goHome(e) {
    e.preventDefault();
    if (this._authenticationService.isSalesCounter()) {
      window.location.href = environment.urls.cms;
    } else {
      window.location.href = environment.urls.website;
    }
  }
}
