import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {forkJoin, Observable, ReplaySubject} from 'rxjs';

@Injectable()
export class PaymentPayoneHostedService {
  private _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};

  constructor(@Inject(DOCUMENT) private readonly document: any) {
  }

  lazyLoad(): Observable<any> {
    return forkJoin([
      this.loadScript('assets/payone/payone_hosted_min.js')
    ]);
  }

  private loadScript(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = () => {
      this._loadedLibraries[url].next(Math.random());
      this._loadedLibraries[url].complete();
    };

    this.document.body.appendChild(script);

    return this._loadedLibraries[url].asObservable();
  }
}
