import {Injectable} from '@angular/core';
import {DefaultDirectDebitComponent} from '../components/form/default/direct-debit.component';
import {DefaultECTerminalComponent} from '../components/form/default/ec-terminal.component';
import {DefaultCashComponent} from '../components/form/default/cash.component';
import {getSession$} from 'fe-starter-shared';
import {PaymentMethod, PaymentMethods} from 'fe-starter-shared';
import {getPaymentMethods$} from 'fe-starter-shared';
import {PaymentFormData} from '../components/form/payment-form.component';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {User} from 'fe-starter-shared';
import {SessionState} from 'fe-starter-shared';
import {PaymentProviderTypes} from 'fe-starter-shared';
import {DefaultInvoiceFilmDistributorComponent} from '../components/form/default/invoice-film-distributor.component';
import {DefaultVoucherComponent} from '../components/form/default/voucher.component';
import {PayoneCreditCardComponent} from '../components/form/payone/credit-card.component';
import {PayoneExternalComponent} from '../components/form/payone/external.component';
import {PayoneExternalDirectlyComponent} from '../components/form/payone/external-directly.component';
import {CrefopaySecureFieldsComponent} from '../components/form/crefopay/secure-fields.component';
import {PaymentInfo} from 'fe-starter-shared';
import {getPaymentInfo$} from 'fe-starter-shared';

@Injectable()
export class PaymentProviderService {

  private _subscription: Subscription;

  private _customer: User;
  private _paymentInfo: PaymentInfo;
  private _paymentMethods: PaymentMethods;

  constructor(private _store: Store<any>) {
    this._subscription = new Subscription();
    this._subscription.add(
      this._store
        .pipe(getSession$)
        .subscribe((session: SessionState) => {
          this._customer = session.customer;
        })
    ).add(
      this._store
        .pipe(getPaymentInfo$)
        .subscribe(paymentInfo => {
          this._paymentInfo = paymentInfo;
        })
    ).add(
      this._store
        .pipe(getPaymentMethods$)
        .subscribe(paymentMethods => this._paymentMethods = paymentMethods)
    );
  }

  getSelectedPaymentMethod(): PaymentMethod {
    if (this._paymentInfo != null && this._paymentInfo.providerType != null) {
      const selectedPaymentMethodId = Object.keys(this._paymentMethods.paymentMethods).find(paymentMethodId => {
        return this._paymentMethods.paymentMethods[paymentMethodId].method === this._paymentInfo.providerType;
      });
      if (selectedPaymentMethodId != null) {
        return this._paymentMethods.paymentMethods[selectedPaymentMethodId];
      }
    }
    return null;
  }

  getComponent(selectedPaymentMethod: PaymentInfo): {component: any, data: PaymentFormData} {
    if (selectedPaymentMethod != null) {

      switch (selectedPaymentMethod.provider) {
        case 'DEFAULT':

          switch (selectedPaymentMethod.providerType) {
            case PaymentProviderTypes.DIRECT_DEBIT:
              return {
                component: DefaultDirectDebitComponent,
                data: new PaymentFormData({
                  customer: this._customer,
                  paymentInfo: this._paymentInfo,
                  selectedPaymentMethod: this.getSelectedPaymentMethod()
                })
              };

            case PaymentProviderTypes.CASH:
              return {
                component: DefaultCashComponent,
                data: new PaymentFormData({
                  paymentInfo: this._paymentInfo,
                  selectedPaymentMethod: this.getSelectedPaymentMethod()
                })
              };

            case PaymentProviderTypes.EC_TERMINAL:
              return {
                component: DefaultECTerminalComponent,
                data: new PaymentFormData({
                  paymentInfo: this._paymentInfo,
                  selectedPaymentMethod: this.getSelectedPaymentMethod()
                })
              };

            case PaymentProviderTypes.VOUCHER:
              return {
                component: DefaultVoucherComponent,
                data: new PaymentFormData({
                  paymentInfo: this._paymentInfo,
                  selectedPaymentMethod: this.getSelectedPaymentMethod()
                })
              };

            case PaymentProviderTypes.INVOICE_FILM_DISTRIBUTOR:
              return {
                component: DefaultInvoiceFilmDistributorComponent,
                data: new PaymentFormData({
                  paymentInfo: this._paymentInfo,
                  selectedPaymentMethod: this.getSelectedPaymentMethod()
                })
              };
          }
          break;

        case 'PAYONE':

          switch (selectedPaymentMethod.providerType) {
            case PaymentProviderTypes.CREDIT_CARD_VISA:
            case PaymentProviderTypes.CREDIT_CARD_MASTER_CARD:
            case PaymentProviderTypes.CREDIT_CARD_AMERICAN_EXPRESS:
            case PaymentProviderTypes.CREDIT_CARD_DINERS_DISCOVER:
            case PaymentProviderTypes.CREDIT_CARD_JCB:
            case PaymentProviderTypes.CREDIT_CARD_MAESTRO_INTERNATIONAL:
            case PaymentProviderTypes.CREDIT_CARD_CHINA_UNION_PAY:
            case PaymentProviderTypes.CREDIT_CARD_UATP_AIRPLUS:
              return {
                component: PayoneCreditCardComponent,
                data: new PaymentFormData({
                  paymentInfo: this._paymentInfo,
                  selectedPaymentMethod: this.getSelectedPaymentMethod()
                })
              };
            case PaymentProviderTypes.PAYPAL:
              return {
                component: PayoneExternalComponent,
                data: new PaymentFormData({
                  paymentInfo: this._paymentInfo,
                  selectedPaymentMethod: this.getSelectedPaymentMethod()
                })
              };
            case PaymentProviderTypes.PAYDIREKT:
            case PaymentProviderTypes.KLARNA:
              return {
                component: PayoneExternalDirectlyComponent,
                data: new PaymentFormData({
                  paymentInfo: this._paymentInfo,
                  selectedPaymentMethod: this.getSelectedPaymentMethod()
                })
              };
            case PaymentProviderTypes.I_DEAL:
              return { // TODO: Add new components
                component: DefaultCashComponent,
                data: new PaymentFormData({
                  paymentInfo: this._paymentInfo,
                  selectedPaymentMethod: this.getSelectedPaymentMethod()
                })
              };
          }
          break;

        case 'CREFOPAY':

          switch (selectedPaymentMethod.providerType) {
            case PaymentProviderTypes.CREDIT_CARD:
            case PaymentProviderTypes.PAYPAL:
              return {
                component: CrefopaySecureFieldsComponent,
                data: new PaymentFormData({
                  paymentInfo: this._paymentInfo,
                  selectedPaymentMethod: this.getSelectedPaymentMethod()
                })
              };
          }
          break;

        case 'SAFERPAY':

          switch (selectedPaymentMethod.providerType) {
            case PaymentProviderTypes.DIRECT_DEBIT:
              return {
                component: DefaultDirectDebitComponent,
                data: new PaymentFormData({
                  customer: this._customer,
                  paymentInfo: this._paymentInfo,
                  selectedPaymentMethod: this.getSelectedPaymentMethod()
                })
              };
          }
          break;
      }
    }
    return null;
  }
}
