export const localeBaseEn = {
  lang: 'en',
  data: {
    PAYMENT: {
      LOADING: 'Loading Payment Methods',
      PAYMENT_FORM: {
        LABEL_ACCOUNT_HOLDER: 'Account Holder*',
        LABEL_IBAN: 'IBAN*',
        PAYMENT_BUTTON: 'Order',
      },
      OPTIONS_TITLE: 'The following Payment methods are supported:',
      SELECT_TITLE: 'Choose your method of payment:',
      PAYMENT_METHODS: {
        DIRECT_DEBIT: 'Direct Debit',
        VOUCHER: 'Voucher',
        EC_TERMINAL: 'EC Terminal',
        CASH: 'Cash',
        INVOICE_FILM_DISTRIBUTOR: 'Film distributor voucher',
        PAYPAL: 'PayPal',
        PAYDIREKT: 'Paydirekt',
        KLARNA: 'Direct Debit',
        CREDIT_CARD: 'Credit Card',
        CREDIT_CARD_VISA: 'Visa',
        CREDIT_CARD_MASTER_CARD: 'mastercard',
        CREDIT_CARD_AMERICAN_EXPRESS: 'American Express',
        CREDIT_CARD_DINERS_DISCOVER: 'Diners Club',
        CREDIT_CARD_JCB: 'JCB',
        CREDIT_CARD_MAESTRO_INTERNATIONAL: 'maestro',
        CREDIT_CARD_CHINA_UNION_PAY: 'Union Pay',
        CREDIT_CARD_UATP_AIRPLUS: 'Airplus',
        I_DEAL: 'iDeal',
      }
    },
  }
};
