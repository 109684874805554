import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'c360-browser-check-modal.component',
  templateUrl: './browser-check-modal.component.html',
})
export class BrowserCheckModalComponent {

  constructor(private _activeModal: NgbActiveModal) {
  }

  public onClose(): void {
    this._activeModal.dismiss();
  }

  public onRefund(): void {
    this._activeModal.close();
  }
}
