import {PaymentInfoStatus} from '../enums/payment-info-status.enum';

export class PaymentInfo {
  status: PaymentInfoStatus;
  provider: string;
  providerType: string;
  providerTypeId: string;
  providerTypeIdMasked: string;
  providerTypeReference: string;
  providerTypeCustomerName: string;
  providerTypeRedirectUrl: string;
  amount: number;

  // constructor(
  //   status: PaymentInfoStatus,
  //   provider: string,
  //   providerType: string,
  //   providerTypeId: string,
  //   providerTypeIdMasked: string,
  //   providerTypeCustomerName: string,
  //   providerTypeRedirectUrl: string,
  //   amount: number
  // ) {
  //   this.status = status;
  //   this.provider = provider;
  //   this.providerType = providerType;
  //   this.providerTypeId = providerTypeId;
  //   this.providerTypeIdMasked = providerTypeIdMasked;
  //   this.providerTypeCustomerName = providerTypeCustomerName;
  //   this.providerTypeRedirectUrl = providerTypeRedirectUrl;
  //   this.amount = amount;
  // }
}
