import {version} from './version';
import {Environment} from './environment.interface';

export const environment: Environment = {
  production: false,
  hasDevTools: true,
  useMocks: false,

  version,
  clientType: 'BOOKING',

  socketRequestTimeout: 40000,
  maxSocketReconnectAttempts: 15,

  logLevel: 2, // 0=ALL, 1 >= Info, 2 >= Warning, 3 >=Error

  client: {
    name: 'MercatorJazz'
  },

  locale: 'de-DE', // 'de-DE' for Germany, 'fr-FR' for France ...
  currency: 'EUR',
  eventType: 'MUSICAL',
  eventDefaultLanguage: 'GERMAN',

  languages: ['de'],
  languageDir: './i18n/client',
  defaultLanguage: 'de',

  // Location Config
  locations: {
    'bd4b82d6-92be-454a-aca1-d7f0168cffd3': {
      website: 'https://mercatorjazz.de'
    }
  },

  urls: {
    website: 'https://mercatorjazz.de',
    cms: 'https://cms-ger-mercatorjazz.c360-dev.m2.krankikom.de',
    cmsConfirmation: '/counter/confirmation/',
    cmsSchedule: '/counter/schedule/',
    services: 'https://booking-ger-mercatorjazz.c360-dev.m2.krankikom.de/gateway/',
    process: 'booking-session/process',
    webSocket: 'booking-session/ws',
    auth: 'auth/oauth/token',
    passwordReset: 'auth/users/custom/passwordReset',
    verifyAccount: 'auth/users/custom/registration',
    changeEmailAddress: 'auth/users/custom/changeEmailAddress',
    users_search: 'auth/users/search/findByExternalId',
    users_patch: 'auth/users',
    users_change_email: 'auth/users/custom/changeEmailAddress',
    transaction_searches: 'booking-transaction/transactionSearches/search/findAllByCustomerUserExternalId',
    transaction_refund: 'booking-transaction/transactions/custom/refund'
  },

  oauth: {
    clientId: 'e95ecb4b-ae9d-4bc5-85ad-841e5817afe9',
    grant_type_login: 'password',
    grant_type_refresh: 'refresh_token'
  },

  isQuickbookingSupported: false,

  seating: {
    animations: {
      select: {
        type: 'flip',
        config: {
          speed: 0.5
        },
      },
      unselect: {
        type: 'flip',
        config: {
          speed: 0.5
        },
      }
    }
  },

  tracking: {
    ids: [],
    dataLayerName: 'dataLayer',
    language: 'en',
    currency: 'EUR'
  },
  userPermissionOrder: [
    'ROLE_ADMIN',
    'ROLE_MANDATOR',
    'ROLE_LOCATION',
    'ROLE_SALES_COUNTER',
    'ROLE_PRE_SALES_COUNTER',
    'ROLE_TICKET_VALIDATOR',
    'ROLE_CUSTOMER',
  ],
  media: {
    poster: '/assets/images/placeholder/media_poster.png',
    design: '/assets/images/placeholder/media_design.png'
  },

  ignoredPaymentMethodsForSalesCounter: [
    'VOUCHER',
    'DIRECT_DEBIT'
  ],
  ignoredPaymentMethods: [
    'VOUCHER'
  ],

  cdnImage: {
    host: 'https://ik.imagekit.io/seneahqsrjlxa/t360-development'
  }
};
