import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {PaymentFormComponent, PaymentFormData} from '../payment-form.component';
import {PaymentFormResult} from '../../../models/payment-form-result.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as sharedSocketsActions from 'fe-starter-shared';
import * as sharedProcessActions from 'fe-starter-shared';
import {getPriceInfo$, getProcess$, PaymentInfo, PaymentInfoStatus, PriceInfo, ProcessState, ValidationError} from 'fe-starter-shared';
import {Store} from '@ngrx/store';
import {getCartState$, isRequestPending$} from '../../../../cart/store';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {getPaymentInfo$} from '../../../../../../../../../fe-starter-shared/src/lib/store/payment-info';
import {PaymentInfoRequestType} from '../../../../../../../../../fe-starter-shared/src/lib/enums/payment-info-request-type.enum';

declare var Payone: any;

@Component({
  templateUrl: './external.component.html'
})
export class PayoneExternalComponent extends PaymentFormComponent implements OnInit, OnDestroy {
  data: PaymentFormData;
  validationErrors: ValidationError[] = [];
  onValid: EventEmitter<PaymentFormResult> = new EventEmitter<PaymentFormResult>();

  _result: PaymentInfo = new PaymentInfo();
  _subscription: Subscription;

  paymentForm: FormGroup;
  public useStoredPayment: boolean;
  public isRequestPending: boolean;
  public priceInfo: PriceInfo;
  public skipSaleFee: boolean;
  public externalPaymentWaitingTime: number;

  constructor(private _store: Store<any>,
              private _cd: ChangeDetectorRef,
              private _formBuilder: FormBuilder) {
    super();
    this._subscription = new Subscription();
    this._subscription.add(
      this._store.pipe(isRequestPending$)
        .subscribe(isRequestPending => this.isRequestPending = isRequestPending)
    ).add(
      this._store.pipe(getCartState$)
        .subscribe(cartState => this.skipSaleFee = cartState.skipSaleFee)
    ).add(
      this._store.pipe(getPriceInfo$)
        .subscribe((priceInfo: PriceInfo) => this.priceInfo = priceInfo)
    ).add(
      this._store.pipe(getProcess$)
        .subscribe((processState: ProcessState) => {
          if (processState.externalPaymentWaitingTime) {
            this.externalPaymentWaitingTime = moment.duration(processState.externalPaymentWaitingTime).asMinutes();
          } else {
            this.externalPaymentWaitingTime = null;
          }
        })
    ).add(
      this._store.pipe(getPaymentInfo$)
      .subscribe(paymentInfo => {
        if (paymentInfo != null && paymentInfo.status === PaymentInfoStatus.INCOMPLETE) {
          this._store.dispatch(
            new sharedProcessActions.ProcessPaymentInfoRequestSent({
              type: PaymentInfoRequestType.INIT,
              provider: paymentInfo.provider,
              providerType: paymentInfo.providerType,
              providerTypeId: null,
              providerTypeIdMasked: null,
              providerTypeCustomerName: null,
              skipSaleFee: false
            })
          );
        }
      })
    );
  }

  ngOnInit(): void {
    this.initForms();
    this.initPaymentMethod();
  }

  ngOnDestroy() {
    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  openRedirectUrl(): void {
    this._store.dispatch(
      new sharedSocketsActions.SocketsCloseConnection()
    );
    setTimeout(() => window.location.href = this.data.paymentInfo.providerTypeRedirectUrl, 1250);
  }

  hasRedirectPayment(): boolean {
    // tslint:disable-next-line:max-line-length
    return this.data.paymentInfo != null && (this.data.paymentInfo.status === PaymentInfoStatus.REDIRECT_NEEDED || this.data.paymentInfo.status === PaymentInfoStatus.REDIRECT_WAITING_FOR_PROVIDER_STATUS);
  }

  hasStoredPayment(): boolean {
    return this.data.paymentInfo != null && this.data.paymentInfo.status === PaymentInfoStatus.VALID;
  }

  private initPaymentMethod(reset: boolean = false) {
    if (!reset && this.hasStoredPayment()) {
      this.useStoredPayment = true;
      this.onValid.emit({
        useStoredPayment: this.useStoredPayment,
        bookDirectly: false,
        payment: null
      });
    } else {
      this.useStoredPayment = false;
      this._result = {
        ...new PaymentInfo(),
        provider: this.data.paymentInfo.provider,
        providerType: this.data.paymentInfo.providerType,
      };
      this.paymentForm.patchValue(this._result);
    }
  }

  private initForms(): void {
    this.paymentForm = this._formBuilder
      .group({});

    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  submitForm(): void {
    if (this.paymentForm.valid) {
      this.onValid.emit({
        useStoredPayment: this.useStoredPayment,
        bookDirectly: false,
        payment: {
          ...this._result
        }
      });
    } else {
      this.onValid.emit(null);
    }
  }

  public clearStoredPayment() {
    this.initPaymentMethod(true);
  }

  setData(data: PaymentFormData): void {
    this.data = data;
    try {
      this._cd.detectChanges();
    } catch (e) {
      //
    }
  }

  setValidationErrors(validationErrors: ValidationError[]): void {
    this.validationErrors = validationErrors;

    if (this.validationErrors != null) {
      this.validationErrors.forEach(validationError => {
        if (this.paymentForm.controls[validationError.field] != null) {
          this.paymentForm.controls[validationError.field].setErrors({remote: {invalid: validationError.message}});
        }
      });
    }
  }
}
