import {Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {LegalModalType, LegalService} from '../../services/legal.service';

@Component({
  selector: 'c360-footer',
  templateUrl: './footer.component.html'
})

export class FooterComponent implements OnInit {

  @ViewChild('legalContent', {static: false}) legalContent;

  public version: string = null;
  public content: string;

  public legalModalType = LegalModalType;

  /** Constructor */
  constructor(private _legalService: LegalService) {
  }

  ngOnInit(): void {
    this.version = environment.version;
  }

  openExternalContent(type: LegalModalType = LegalModalType.NONE) {
    this._legalService.show(type);
  }
}
