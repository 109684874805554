import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Store} from '@ngrx/store';
import {getPaymentInfo$, PaymentInfo, RootState, ValidationError} from 'fe-starter-shared';
import {PaymentFormDirective} from './payment-form-host.directive';
import {PaymentFormComponent} from './payment-form.component';
import {PaymentProviderService} from '../../service/payment-provider.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'c360-payment-form-holder',
  templateUrl: './payment-form-holder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentFormHolderComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild(PaymentFormDirective, {static: true}) _paymentFormHost: PaymentFormDirective;
  @Input() validationErrors: ValidationError[] = [];
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onValid: EventEmitter<PaymentInfo> = new EventEmitter<PaymentInfo>();

  private _subscriptions: Subscription;
  private _containerSubscription: Subscription;
  public paymentInfo: PaymentInfo = null;

  public _componentRef: ComponentRef<unknown>;
  public loading = true;

  constructor(private _store: Store<RootState>,
              private _cd: ChangeDetectorRef,
              private componentFactoryResolver: ComponentFactoryResolver,
              private _paymentProviderService: PaymentProviderService) {
  }

  ngOnInit(): void {
    this._subscriptions = this._store.pipe(getPaymentInfo$).subscribe((selected) => {
      if (this.paymentInfo !== selected) {
        this.paymentInfo = selected;
        this.setPaymentForm();
      }
    });
  }

  ngOnDestroy(): void {
    if (this._subscriptions != null) {
      this._subscriptions.unsubscribe();
    }
    if (this._containerSubscription != null) {
      this._containerSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validationErrors != null) {
      if (this._componentRef != null) {
        (this._componentRef.instance as PaymentFormComponent).setValidationErrors(
          changes.validationErrors.currentValue
        );
      }
    }
  }

  setPaymentForm() {
    if (this._paymentFormHost) {
      if (this._containerSubscription != null) {
        this._containerSubscription.unsubscribe();
      }
      const viewContainerRef = this._paymentFormHost.viewContainerRef;
      viewContainerRef.clear();

      const paymentProviderComponent = this._paymentProviderService.getComponent(this.paymentInfo);
      if (paymentProviderComponent != null) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(paymentProviderComponent.component);

        this._componentRef = viewContainerRef.createComponent(componentFactory);
        (this._componentRef.instance as PaymentFormComponent).setData(paymentProviderComponent.data);

        this._containerSubscription = (this._componentRef.instance as PaymentFormComponent).onValid.subscribe(
          (paymentInfoTmp: PaymentInfo) => {
            this.onValid.emit(paymentInfoTmp);
          }
        );

        this.loading = false;
      }
    }
  }
}
