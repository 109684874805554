<ng-container *ngIf="isRequestPending">
  <c360-loading-bar message="{{'PAYMENT.LOADING' | translate}}"></c360-loading-bar>
</ng-container>

<ng-container *ngIf="!isRequestPending && priceInfo">
  <p>
    <span *ngIf="data.selectedPaymentMethod"><i class="{{data.selectedPaymentMethod.icon}}" aria-hidden="true"></i> {{'PAYMENT.PAYMENT_METHODS.' + data.selectedPaymentMethod.method | translate}}</span><br/>
    <b class="mt-4" *ngIf="skipSaleFee" [translate]="'PAYMENT.TOTAL'" [translateParams]="{amount: priceInfo.fullAmount  | localeCurrency:'symbol':'1.2'}"></b>
    <b class="mt-4" *ngIf="!skipSaleFee" [translate]="'PAYMENT.TOTAL'" [translateParams]="{amount: priceInfo.fullAmountWithFee  | localeCurrency:'symbol':'1.2'}"></b>
  </p>
  <div *ngIf="hasStoredPayment() && data.paymentInfo.providerType == 'CREDIT_CARD'">
    <div>
      <span [translate]="'PAYMENT.STORED.CREDIT_CARD.TITLE'"></span><br/>
      <b>{{data.paymentInfo.providerTypeIdMasked}}</b><br/>
      <button class="btn btn-secondary btn-block mt-3 py-3" (click)="clearStoredPayment()" [translate]="'PAYMENT.STORED.CREDIT_CARD.CHANGE'"></button>
    </div>
  </div>

  <form role="form"
        *ngIf="paymentForm && !hasRedirectPayment() && !hasStoredPayment()"
        (ngSubmit)="submitForm()"
        [formGroup]="paymentForm">
    <ng-container *ngIf="data.paymentInfo.providerType == 'CREDIT_CARD'">
      <input type="hidden" data-crefopay="paymentMethod" name="paymentMethodSelect" value="CC3D"/>
      <div>
        <label for="accountHolder" [translate]="'PAYMENT.FORMS.CREDIT_CARD.LABEL_ACCOUNT_HOLDER'"></label><br/>
        <span id="accountHolder" class="inputIframe" data-crefopay-placeholder="paymentInstrument.accountHolder"></span>
      </div>
      <div>
        <label for="cardpan" [translate]="'PAYMENT.FORMS.CREDIT_CARD.LABEL_CARDPAN'"></label><br/>
        <span id="cardpan" class="inputIframe" data-crefopay-placeholder="paymentInstrument.number"></span>
      </div>
      <div>
        <label for="cardcvc2" [translate]="'PAYMENT.FORMS.CREDIT_CARD.LABEL_CVC2'"></label><br/>
        <span id="cardcvc2" class="inputIframe" data-crefopay-placeholder="paymentInstrument.cvv"></span>
      </div>
      <div>
        <label for="expireInput" [translate]="'PAYMENT.FORMS.CREDIT_CARD.LABEL_EXPIRY'"></label><br/>
        <span id="expireInput" class="inputIframe" data-crefopay-placeholder="paymentInstrument.validity"></span>
      </div>
    </ng-container>
    <ng-container *ngIf="data.paymentInfo.providerType == 'PAYPAL'">
      <input type="hidden" data-crefopay="paymentMethod" name="paymentMethodSelect" value="PAYPAL"/>
    </ng-container>

    <div *ngIf="showErrorRequiredFields" role="alert" class="alert alert-warning text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.' + data.paymentInfo.providerType +'.REQUIRED_FIELDS'"></div>
    <div *ngIf="showErrorNotValid" role="alert" class="alert alert-warning text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.' + data.paymentInfo.providerType +'.NOT_VALID'"></div>
    <div *ngIf="data.paymentInfo.status === 'REDIRECT_ABORTED'" role="alert" class="alert alert-warning text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.' + data.paymentInfo.providerType +'.' + data.paymentInfo.status"></div>
    <div *ngIf="data.paymentInfo.status === 'REDIRECT_FAILED'" role="alert" class="alert alert-danger text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.' + data.paymentInfo.providerType +'.' + data.paymentInfo.status"></div>

    <ng-container *ngIf="data.paymentInfo.providerType == 'CREDIT_CARD'">
      <span [translate]="'BASE.FORM.REQUIRED_FIELDS'"></span>

      <div *ngIf="externalPaymentWaitingTime" role="alert" class="alert alert-success text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.CREDIT_CARD.WAITING_TIME'" [translateParams]="{time: externalPaymentWaitingTime}"></div>

      <button type="submit" class="btn btn-block btn-primary btn-lg py-3" [translate]="'PAYMENT.FORMS.CREDIT_CARD.SUBMIT'"></button>
    </ng-container>
    <ng-container *ngIf="data.paymentInfo.providerType == 'PAYPAL'">
      <div *ngIf="externalPaymentWaitingTime" role="alert" class="alert alert-success text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.PAYPAL.WAITING_TIME'" [translateParams]="{time: externalPaymentWaitingTime}"></div>

      <button type="submit" class="btn btn-block btn-primary btn-lg py-3" [translate]="'PAYMENT.FORMS.PAYPAL.SUBMIT'"></button>
    </ng-container>

  </form>
  <div *ngIf="hasRedirectPayment()">
    <div *ngIf="data.paymentInfo.status === 'REDIRECT_NEEDED'">
      <ng-container *ngIf="data.paymentInfo.providerType == 'CREDIT_CARD'">
        <div *ngIf="externalPaymentWaitingTime" role="alert" class="alert alert-success text-center mt-4 mb-4" [translate]="'PAYMENT.FORMS.CREDIT_CARD.WAITING_TIME'" [translateParams]="{time: externalPaymentWaitingTime}"></div>

        <button class="btn btn-block btn-primary btn-lg py-3" (click)="openRedirectUrl()" [translate]="'PAYMENT.STORED.' + data.paymentInfo.providerType +'.REDIRECT'"></button>
      </ng-container>
      <ng-container *ngIf="data.paymentInfo.providerType != 'CREDIT_CARD'">
        <c360-loading-bar message="{{'PAYMENT.FORMS.' + data.selectedPaymentMethod.method + '.LOADING' | translate}}"></c360-loading-bar>
      </ng-container>
    </div>
    <c360-loading-bar *ngIf="data.paymentInfo.status === 'REDIRECT_WAITING_FOR_PROVIDER_STATUS'" message="{{'PAYMENT.FORMS.' + data.selectedPaymentMethod.method + '.' + data.paymentInfo.status | translate}}"></c360-loading-bar>
  </div>
</ng-container>
