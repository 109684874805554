export const localeBaseEn = {
  lang: 'en',
  data: {
    TICKETING: {
      LOADING: 'Loading best tickets',
      HEADER: 'Tickets',
      SUBTITLE: 'You have selected {value} seats. Below you can select your tickets',
      FOOTER: 'Additional Information',
      TICKETS_FROM: 'from',
      TICKETS_AVAILABLE: 'available',
      GO_TO_SEAT_SELECTION: 'Select your seats'
    }
  }
};
