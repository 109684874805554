<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="error-template">
        <h1 [translate]="'PAGE_NOT_FOUND.TITLE'"></h1>
        <h2 [translate]="'PAGE_NOT_FOUND.SUBTITLE'"></h2>
        <div class="error-details" [translate]="'PAGE_NOT_FOUND.DETAILS'"></div>
        <div class="error-actions">
          <a href="#" (click)="goHome($event)" class="btn btn-primary btn-lg">
            <span class="glyphicon glyphicon-home"></span> <span [translate]="'PAGE_NOT_FOUND.GO_HOME'"></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
