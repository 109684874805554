export const localeBaseDe = {
  'lang': 'de',
  'data': {
    'CLIENT': {
      'NAME': 'Local Test Client'
    },
    'BASE': {
      'LOADING': 'Lade Anwendung...',
      'TITLE': 'Buchung',
      'ABORT': 'Abbruch',
      'REALLY_QUIT': 'Wollen sie die Buchung wirklick verlassen?',
      'BACK_TO_HOMEPAGE': 'Zurück zur Website',
      'CANCEL': 'Abbruch',
      'NEXT': 'Weiter',
      'PREVIOUS': 'Zurück',
      'CLOSE': 'Schließen',

      'SESSION_EXISTS': 'Möchten Sie Ihre letzte Sitzung wieder aufnehmen oder eine neue starten?',
      'CONTINUE_SESSION': 'Sitzung wiederaufnehmen',
      'NEW_SESSION': 'Neue Sitzung starten',

      'STEPS': {
        'SEATING': 'Ticketauswahl',
        'PERSONAL_DETAILS': 'Persönliche Daten',
        'PAYMENT': 'Bezahlen',
        'CONFIRMATION': 'Bestätigung',
      },
      'FORM': {
        'REQUIRED_FIELDS': '* Pflichtfelder'
      },
      'ERROR': {
        'TITLE': 'Es ist ein Fehler aufgetreten',
        'DETAILS': 'Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut',
        'CODE': 'Code: {{code}}',
        'SOCKET-X': 'Verbindungsfehler. Prüfen Sie Ihre Internetverbindung und klicken Sie auf \'Verbinden\'.' +
          'Sollte das das Problem nicht beheben können Sie es auch mit einem Beustart versuchen.',
        'RECONNECT': 'Verbinden',
        'RESTART': 'Neustart',
        'ALERT_RETRY_LAST': 'Fortfahren',
        'RETRY_INFO': 'Erneuter Versuch in {{seconds}} Sekunden',
        'RETRY_PROGRESS': '({{attempt}} / {{max}})',
        'RETRY_DONE': 'Bitte versuchen Sie es später erneut',
        'EXIT': 'Abbrechen',
        'BFE-VA-1': {
          'TITLE': 'Bestätigung fehlgeschlagen',
          'MESSAGE': 'Ihr Bestätigungslink ist leider abgelaufen. Bitte legen Sie erneut einen Account an und bestätigen Sie den Account innerhalb von 72 Stunden.'
        },
        'PROCESS_DELETE': {
          'TITLE': 'Ihre Sitzung ist abgelaufen',
          'MESSAGE': 'Um Tickets buchen zu können, müssen Sie eine neue Sitzung starten. Klicken Sie dafür einfach auf \'Neustart\''
        },
        'SESSION_DELETE': {
          'TITLE': 'Ihre Sitzung ist abgelaufen',
          'MESSAGE': 'Um Tickets buchen zu können, müssen Sie eine neue Sitzung starten. Klicken Sie dafür einfach auf \'Neustart\''
        },
        'HTTP': {
          'TITLE': 'Verbindungsfehler',
          'CONTENT': 'Leider besteht ein Problem mit der Verbindung.'
        },
        'BFE-4': {
          'TITLE': 'Sitzplatzauswahl derzeit möglich',
          'MESSAGE': 'Die gewählten Sitze konnten nicht selektiert werden. Bitte versuchen Sie es noch einmal.'
        },
        'BFE-5': {
          'TITLE': 'Warenkorb Fehler',
          'MESSAGE': 'Der Warenkorb konnte nicht abgefragt werden. Bitte versuchen Sie es noch einmal.'
        },
        'BFE-6': {
          'TITLE': 'Ein Problem ist aufgetreten',
          'MESSAGE': 'Es gab ein technisches Problem. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, laden Sie die Seite bitte neu.'
        },
      }
    },
    'PAGE_NOT_FOUND': {
      'TITLE': 'Oops!',
      'SUBTITLE': '404 Not Found',
      'DETAILS': 'Es tut uns Leid. Die gewünschte Seite konnten wir nicht finden!',
      'GO_HOME': 'Zurück',
      'CONTACT_SUPPORT': 'Support'
    },

    'PAGE_TITLES': {
      'BASE': 'C360',
      'CHECKOUT': {
        'TITLE': 'Kartenkauf',
        'INIT': 'Init',
        'INFO': 'Informationen',
        'SEATING': 'Sitzplatzauswahl',
        'DETAILS': 'Persönliche Details',
        'PAYMENT': 'Zahlung',
      },
      'CONFIRMATION': {
        'TITLE': 'Buchungsbestätigung'
      },
      'CHANGE_EMAIL_ADDRESS': {
        'TITLE': 'E-Mail-Addresse ändern'
      },
      'VERIFY_ACCOUNT': {
        'TITLE': 'Account bestätigen'
      },
      'PASSWORD_RESET': {
        'TITLE': 'Passwort zurücksetzen'
      },
      'ERROR': {
        'TITLE': 'Fehler'
      },
      'PAGENOTFOUND': {
        'TITLE': '404'
      }
    },

    'FOOTER': {
      'IMPRINT': 'Impressum',
      'TERMS_AND_CONDITIONS': 'AGB',
      'DATA_PROTECTION_GUIDELINES': 'Datenschutz',
    },

    'VALIDATION_ERROR': {
      'REQUIRED': 'Benötigt',
      'REQUIRED_FIELD': 'Bitte {{label}} angeben',
      'INVALID_EMAIL': 'Bitte eine gültige E-Mail-Adresse angeben',
      'INVALID_PASSWORD': 'Das Passwort muss mindestes 8 Zeichen lang sein ' +
        'und mindestens einen Buchstaben, eine Zahl und ein Sonderzeichen enthalten.',
      'INVALID_LENGTH': 'Mindestens {{params.requiredLength}} Zeichen',
      'MATCH_PASSWORD': 'Die Passwörter stimmen nicht überein',
      'MATCH_EMAIL': 'Die E-Mail-Adressen stimmen nicht mit der zuvor angegebenen überein',
      'SECURE_PASSWORD': 'Das Passwort muss mindestes 8 Zeichen lang sein ' +
        'und mindestens einen Buchstaben, eine Zahl und ein Sonderzeichen enthalten.',
      'IBAN': 'Das Format der eingegebenen IBAN ist fehlerhaft.',
      'REMOTE': '{{params.invalid}}',
      'VOUCHER_ALREADY_USED': 'Dieser Gutschein wurde bereits berücksichtigt.'
    }
  }
};
