import {Store} from '@ngrx/store';
import {LayoutState} from './layout.reducers';
import {AppState} from '../reducers';
import {Observable} from 'rxjs';

export function getLayout$(state$: Store<AppState>): Observable<LayoutState> {
  return state$.select(state => state.layout);
}

export function getShowSplashscreen$(state$: Store<AppState>): Observable<boolean> {
  return state$.select(state => state.layout.showSplashscreen);
}
