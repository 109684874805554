import {Actions, Effect, ofType} from '@ngrx/effects';
import * as cartActions from './cart.actions';
import * as sharedProcessActions from 'fe-starter-shared';
import * as sharedSeatingActions from 'fe-starter-shared';
import * as sharedErrorUserActions from 'fe-starter-shared';
import {
  AuthenticationService,
  ClientConfigService,
  ErrorCodes,
  ErrorUserActions,
  getPerformance$,
  getPriceCategories$,
  getProcess$,
  getSelectedSpaces$,
  PriceInfoRequest,
  TrackingService
} from 'fe-starter-shared';
import {getCartState$} from './cart.selectors';
import {Store} from '@ngrx/store';
import * as moment from 'moment';
import {isUndefined} from 'util';
import {debounceTime, map, mergeMap, withLatestFrom} from 'rxjs/operators';

export class CartEffects {

  private _requestTimeout: number = this._clientConfigService.getConfig('socketRequestTimeout') || 40000;
  private _timeoutCounter = 0;

  @Effect()
  onCartInit = this.actions$.pipe(
    ofType<cartActions.InitCart>(cartActions.CartActions.INIT_CART),
    withLatestFrom(
      this._store.pipe(getProcess$),
      this._store.pipe(getPerformance$),
      this._store.pipe(getCartState$)
    ),
    mergeMap(([, process, performance, currentState]) => {
      const actions = [];
      if (currentState.skipSaleFee == null) {
        let skipSaleFee = false;
        if (process != null && performance != null && performance.startDate != null && process.createTimestamp != null) {
          if (isUndefined(performance.startDate) || isUndefined(process.createTimestamp)) {
            this._store.dispatch(
              new sharedErrorUserActions.HandleError({
                errorTitle: 'BASE.ERROR.' + ErrorCodes.BFE6 + '.TITLE',
                errorCode: ErrorCodes.BFE6,
                errorUserMessage: 'BASE.ERROR.' + ErrorCodes.BFE6 + '.MESSAGE',
                errorAction: ErrorUserActions.EXIT
              })
            );
          } else if (this._authenticationService.isSalesCounter(false)) {
            // Skip Sale Fee if performance is today (same day as createTimestamp)
            // or if performance is in past
            // tslint:disable-next-line:max-line-length
            skipSaleFee = moment(performance.startDate).isSame(moment(process.createTimestamp), 'day') || moment(performance.startDate).isBefore(moment(process.createTimestamp));
          }
        }
        actions.push(
          new cartActions.SetFee(skipSaleFee)
        );
      }

      // Check for incomplete Selection (Pending)
      if (process != null && process.selectedSpaces != null && process.selectedSpaces.length > 0) {
        if (process.priceInfo == null) {
          // Revoke selection
          actions.push(
            new sharedSeatingActions.SpaceSelectionRequest(null)
          );
        }
      }

      if (actions.length === 0) {
        actions.push(
          new cartActions.CartUpdateSkip()
        );
      }
      return actions;
    })
  );

  @Effect()
  onSpaceSelectionSuccessful = this.actions$.pipe(
    ofType<sharedSeatingActions.SpaceSelectionSuccess>(sharedSeatingActions.SeatingActions.SPACE_SELECTION_SUCCESS),
    map(action => action.payload),
    withLatestFrom(
      this._store.pipe(getPriceCategories$)
    ),
    map(([spaceSelection, priceCategories]) => {
      const numberOfSelectedTickets = priceCategories.reduce((a, b) => (b.amount == null) ? a : a + b.amount, 0);

      if (spaceSelection.selectedSpaces != null && numberOfSelectedTickets === spaceSelection.selectedSpaces.length) {

        if (spaceSelection.selectedSpaces.length > 0) {
          return new cartActions.CartUpdateRequest();
        }
      }
      return new cartActions.CartUpdateSkip();
    })
  );

  @Effect()
  onCartUpdateRequest = this.actions$.pipe(
    ofType<cartActions.CartUpdateRequest>(cartActions.CartActions.CART_UPDATE_REQUEST),
    withLatestFrom(
      this._store.pipe((getSelectedSpaces$)),
      this._store.pipe((getPriceCategories$)),
      this._store.pipe((getCartState$))
    ),
    mergeMap(([, spaceSelection, priceCategories, cartState]) => {
      const actions = [];

      const priceMapping: PriceInfoRequest = {
        priceCategorySpacesMappings: [],
        voucherCodes: []
      };

      let selectedSpaces = [];
      if (spaceSelection != null) {
        selectedSpaces = [
          ...spaceSelection
        ];
      }

      priceCategories.forEach((priceCategory) => {

        if (priceCategory != null && (priceCategory.amount != null && priceCategory.amount > 0)) {
          const spacesForMapping = selectedSpaces.slice(0, priceCategory.amount);
          selectedSpaces = [
            ...selectedSpaces.slice(priceCategory.amount, selectedSpaces.length)
          ];
          priceMapping.priceCategorySpacesMappings.push({
            priceCategoryId: priceCategory.id,
            spaceIds: spacesForMapping.map(s => s.spaceId)
          });
        }
      });

      if (cartState.voucherList.length > 0) {
        cartState.voucherList.forEach(voucherCode => {
          if (priceMapping.voucherCodes.indexOf(voucherCode) === -1) {
            priceMapping.voucherCodes.push(voucherCode);
          }
        });
      }

      actions.push(
        new sharedProcessActions.ProcessCartUpdateRequest(priceMapping)
      );
      if (priceMapping.priceCategorySpacesMappings.length > 0) {
        actions.push(
          new cartActions.CheckForResponse()
        );
      }
      return actions;
    })
  );

  @Effect()
  onCheckForResponse = this.actions$.pipe(
    ofType<cartActions.CheckForResponse>(cartActions.CartActions.CHECK_FOR_RESPONSE),
    debounceTime(this._requestTimeout),
    withLatestFrom(this._store.pipe((getCartState$))),
    map(([selectedSpaces, state]) => {
      if (state.requestPending) {
        this._trackingService.pushGAEvent(
          'Error',
          'CartUpdateRequestTimeout',
          'Canceled after ' + (performance.now() - state.requestTimestamp)
        );

        return new cartActions.CartUpdateTimeout();
      }
      return new cartActions.CartUpdateSkip();
    })
  );

  @Effect()
  onCartUpdateTimeout$ = this.actions$.pipe(
    ofType<cartActions.CartUpdateTimeout>(cartActions.CartActions.CART_UPDATE_TIMEOUT),
    withLatestFrom(this._store.pipe((getCartState$))),
    map(([action, state]) => {
      return new sharedErrorUserActions.HandleError({
        errorTitle: 'BASE.ERROR.' + ErrorCodes.CART_TIMEOUT + '.TITLE',
        errorCode: ErrorCodes.CART_TIMEOUT,
        errorUserMessage: 'BASE.ERROR.' + ErrorCodes.CART_TIMEOUT + '.MESSAGE',
        errorAction: ErrorUserActions.EXIT
      });
    })
  );

  @Effect()
  onCartUpdateReceived = this.actions$.pipe(
    ofType<sharedProcessActions.ProcessCartUpdateReceived>(sharedProcessActions.ProcessActions.CART_UPDATE_RECEIVED),
    map(action => action.payload),
    withLatestFrom(this._store.pipe((getCartState$))),
    map(([data, state]) => {
      if (state.requestPending) {
        this._trackingService.pushTiming(
          'Cart',
          'CartUpdateRequest',
          performance.now() - state.requestTimestamp
        );
        return new cartActions.CartUpdateReceived(data);
      }
      return new cartActions.CartUpdateSkip();
    })
  );

  @Effect()
  onAddVoucher$ = this.actions$.pipe(
    ofType<cartActions.CartAddVoucher>(cartActions.CartActions.CART_ADD_VOUCHER),
    map((action) => new cartActions.CartUpdateRequest())
  );

  @Effect()
  onRemoveVoucher$ = this.actions$.pipe(
    ofType<cartActions.CartRemoveVoucher>(cartActions.CartActions.CART_REMOVE_VOUCHER),
    map((action) => new cartActions.CartUpdateRequest())
  );

  constructor(
    private actions$: Actions,
    private _store: Store<any>,
    private _authenticationService: AuthenticationService,
    private _clientConfigService: ClientConfigService,
    private _trackingService: TrackingService) {
  }
}
